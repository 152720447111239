import { grpc } from '@improbable-eng/grpc-web';

import { Value } from '@/lib/constants';
import { environment } from 'environment';

import {
  GenerateOTPRequest,
  VerifyOTPRequest,
  GenerateEmailOTPRequest,
  VerifyEmailOTPRequest,
  CheckMigratedUserRequest,
  ChangeUserEmailRequest,
  ChangeUserPasswordResponse,
  ChangeUserPasswordRequest,
  UpdateUserDataResponse,
  CheckUserWithUsernameResponse,
  CheckUserWithUsernameRequest,
  CheckUserWithEmailResponse,
  CheckUserWithEmailRequest,
  ChangeUserPasswordWithVerifyOTPRequest,
  GetUserByKcidRequest,
  UserRequest,
  GetUserRequest,
  GetUserResponse,
  UpdateLastLoginRequest,
} from '../gen-protos/identity_pb';
import { IdentityClient } from '../gen-protos/identity_pb_service';
import { UserResponse } from '../gen-protos/users_pb';

export interface ChangeUserPasswordArgs {
  password: string;
  userid: string;
  token: string;
}

export interface LoginKeycloakApiPayload {
  error: any;
  access_token: Value<string>;
  refresh_token: Value<string>;
  expires_in: Value<number>;
  refresh_expires_in: Value<number>;
}
export class AuthService {
  private client: IdentityClient;

  constructor() {
    const { iam } = environment;
    this.client = new IdentityClient(iam, {
      transport: grpc.FetchReadableStreamTransport({
        keepalive: true,
        credentials: 'include',
        mode: 'cors',
      }),
    });
  }

  generateotp(phonenumber: string): Promise<any> {
    const generateOTPRequest = new GenerateOTPRequest();
    generateOTPRequest.setPhonenumber(phonenumber);
    return new Promise<any>((resolve, reject) => {
      this.client.generateOTP(generateOTPRequest, (error, response) => {
        if (error == null) {
          resolve(response as any);
        } else {
          reject(error);
        }
      });
    });
  }

  Verifyeotp(phone: string, code: string): Promise<any> {
    const verifyOTPRequest = new VerifyOTPRequest();
    verifyOTPRequest.setPhonenumber(phone);
    verifyOTPRequest.setOtp(code);
    return new Promise<any>((resolve, reject) => {
      this.client.verifyOTP(verifyOTPRequest, (error, response) => {
        if (error == null) {
          resolve(response);
        } else {
          reject(error);
        }
      });
    });
  }

  signup(
    phone: string,
    identifiant: string,
    newPassword: string
  ): Promise<UserResponse> {
    const newUserRequest = new UserRequest();

    newUserRequest.setPhonenumber(`+216${phone}`);
    newUserRequest.setFullname(identifiant);
    newUserRequest.setPassword(newPassword);
    newUserRequest.setUsername(`216${phone}`);
    return new Promise<UserResponse>((resolve, reject) => {
      // const metadata = new grpc.Metadata({Authorization: `Bearer ${token}`});
      this.client.signup(newUserRequest, (error, response) => {
        if (error == null) {
          resolve(response as unknown as UserResponse);
        } else {
          reject(error.message);
        }
      });
    });
  }

  generateEmailotp(email: string): Promise<any> {
    const generateEmailOTPRequest = new GenerateEmailOTPRequest();
    generateEmailOTPRequest.setEmail(email);
    return new Promise<any>((resolve, reject) => {
      this.client.generateEmailOTP(
        generateEmailOTPRequest,
        (error, response) => {
          if (error == null) {
            resolve(response as any);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  VerifyEmaileotp(email: string, otp: string): Promise<any> {
    const verifyemailOTPRequest = new VerifyEmailOTPRequest();
    verifyemailOTPRequest.setEmail(email);
    verifyemailOTPRequest.setOtp(otp);
    return new Promise<any>((resolve, reject) => {
      this.client.verifyEmailOTP(verifyemailOTPRequest, (error, response) => {
        if (error == null) {
          resolve(response);
        } else {
          reject(error);
        }
      });
    });
  }

  ValidateEmail(email: string, otp: string): Promise<any> {
    const verifyemailOTPRequest = new VerifyEmailOTPRequest();
    verifyemailOTPRequest.setEmail(email);
    verifyemailOTPRequest.setOtp(otp);
    return new Promise<any>((resolve, reject) => {
      this.client.validateEmail(verifyemailOTPRequest, (error, response) => {
        if (error == null) {
          resolve(response);
        } else {
          reject(error);
        }
      });
    });
  }

  check(phone: string): Promise<any> {
    const checkMigratedUserRequest = new CheckMigratedUserRequest();
    checkMigratedUserRequest.setPhonenumber(phone);
    return new Promise<any>((resolve, reject) => {
      this.client.checkMigratedUser(
        checkMigratedUserRequest,
        (error, response) => {
          if (error == null) {
            resolve(response);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  change_email(old_email: string, new_email: string): Promise<UserResponse> {
    const changeUserEmailRequest = new ChangeUserEmailRequest();
    changeUserEmailRequest.setNewemail(new_email);
    changeUserEmailRequest.setOldemail(old_email);
    return new Promise<UserResponse>((resolve, reject) => {
      this.client.changeUserEmail(changeUserEmailRequest, (error, response) => {
        if (error == null) {
          resolve(response as unknown as UserResponse);
        } else {
          reject(error);
        }
      });
    });
  }

  change_user_password(
    authToken: string,
    password: string,
    id: string
  ): Promise<ChangeUserPasswordResponse> {
    const metadata = new grpc.Metadata({
      Authorization: `Bearer ${authToken}`,
    });
    const changeUserPasswordRequest = new ChangeUserPasswordRequest();
    changeUserPasswordRequest.setPassword(password);
    changeUserPasswordRequest.setUserid(id);
    return new Promise<ChangeUserPasswordResponse>((resolve, reject) => {
      this.client.changeUserPassword(
        changeUserPasswordRequest,
        metadata,
        (error, response) => {
          if (error == null) {
            resolve(response as ChangeUserPasswordResponse);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  updateUserEmail(
    email: string,

    token: string
  ): Promise<UpdateUserDataResponse> {
    const changeUserEmailRequest = new ChangeUserEmailRequest();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    changeUserEmailRequest.setNewemail(email);

    return new Promise<any>((resolve, reject) => {
      this.client.changeUserEmail(
        changeUserEmailRequest,
        metadata,
        (error, response) => {
          if (error == null) {
            resolve(response);
          } else {
            reject(error.message);
          }
        }
      );
    });
  }

  CheckUserWithusername(
    username: string
  ): Promise<CheckUserWithUsernameResponse> {
    const checkUserWithUsernameRequest = new CheckUserWithUsernameRequest();
    checkUserWithUsernameRequest.setUsername(username);
    return new Promise<CheckUserWithUsernameResponse>((resolve, reject) => {
      this.client.checkUserWithUsername(
        checkUserWithUsernameRequest,
        (error, response) => {
          if (error == null) {
            resolve(response as CheckUserWithUsernameResponse);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  CheckUserWithUseremail(email: string): Promise<CheckUserWithEmailResponse> {
    const checkUserWithEmailRequest = new CheckUserWithEmailRequest();
    checkUserWithEmailRequest.setEmail(email);
    return new Promise<CheckUserWithEmailResponse>((resolve, reject) => {
      this.client.checkUserWithEmail(
        checkUserWithEmailRequest,
        (error, response) => {
          if (error == null) {
            resolve(response as CheckUserWithEmailResponse);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  ChangeUserPasswordWithVerifyOTP(
    password: string,
    id: string,
    phone: string,
    otp: string
  ): Promise<ChangeUserPasswordResponse> {
    const changeUserPasswordRequest =
      new ChangeUserPasswordWithVerifyOTPRequest();
    changeUserPasswordRequest.setPassword(password);
    changeUserPasswordRequest.setUserid(id);
    changeUserPasswordRequest.setPhonenumber(phone);
    changeUserPasswordRequest.setOtp(otp);

    return new Promise<ChangeUserPasswordResponse>((resolve, reject) => {
      this.client.changeUserPasswordWithVerifyOTP(
        changeUserPasswordRequest,
        (error, response) => {
          if (error == null) {
            resolve(response as ChangeUserPasswordResponse);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  GetUserByKcid(id: string): Promise<CheckUserWithEmailResponse> {
    const getUserByKcidRequest = new GetUserByKcidRequest();
    getUserByKcidRequest.setId(id);
    return new Promise<CheckUserWithEmailResponse>((resolve, reject) => {
      this.client.getUserByKcId(getUserByKcidRequest, (error, response) => {
        if (error == null) {
          resolve(response as CheckUserWithEmailResponse);
        } else {
          reject(error);
        }
      });
    });
  }

  loginKeycloakApi = async (
    username: string,
    password: string
  ): Promise<LoginKeycloakApiPayload> => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { client_secret, client_id } = environment;

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const params =
      `username=${username}&` +
      `password=${password}&` +
      `grant_type=password&` +
      `client_id=${client_id}&` +
      `client_secret=${client_secret}&scope=openid`;

    // tslint:disable-next-line:max-line-length
    return fetch(environment.auth_url, {
      method: 'POST',
      body: params,
      headers,
    }).then((res) => res.json());
  };

  verifyLastConnexion({
    phone,
    username,
  }: {
    phone?: string;
    username?: string;
  }): Promise<GetUserResponse> {
    const newUserRequest = new GetUserRequest();

    if (phone) newUserRequest.setPhonenumber(`+216${phone}`);
    if (username) newUserRequest.setUsername(username);

    return new Promise<GetUserResponse>((resolve, reject) => {
      this.client.getUser(newUserRequest, (err, response) => {
        if (err) reject(err.message);
        if (response) resolve(response);
      });
    });
  }

  UpdateLastLogin({
    phone,
    username,
    kcid,
  }: {
    phone?: string;
    username?: string;
    kcid?: string;
  }): Promise<null> {
    const updateLastLoginRequest = new UpdateLastLoginRequest();

    if (phone) updateLastLoginRequest.setPhonenumber(phone);
    if (username) updateLastLoginRequest.setUsername(username);
    if (kcid) updateLastLoginRequest.setKcid(kcid);

    return new Promise<null>((resolve, reject) => {
      this.client.updateLastLogin(updateLastLoginRequest, (err, response) => {
        if (err) reject(err);
        if (response) resolve(null);
      });
    });
  }

  changeUserPassword({
    password,
    userid,
    token,
  }: ChangeUserPasswordArgs): Promise<ChangeUserPasswordResponse> {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    const changeUserPasswordRequest = new ChangeUserPasswordRequest();
    changeUserPasswordRequest.setPassword(password);
    changeUserPasswordRequest.setUserid(userid);
    return new Promise<ChangeUserPasswordResponse>((resolve, reject) => {
      this.client.changeUserPassword(
        changeUserPasswordRequest,
        metadata,
        (err, res) => {
          if (err) reject(err.message);
          if (res) resolve(res);
        }
      );
    });
  }

  updateLastConnexion({
    phone,
    username,
    kcid,
  }: {
    phone?: string;
    username?: string;
    kcid?: string;
  }): Promise<any> {
    const newUserRequest = new GetUserRequest();
    if (kcid) newUserRequest.setKcid(kcid);
    else if (phone) newUserRequest.setPhonenumber(phone);
    else if (username) newUserRequest.setUsername(username);

    return new Promise<any>((resolve, reject) => {
      this.client.updateLastLogin(newUserRequest, (err, response) => {
        if (err) reject(err.message);
        if (response) resolve(response);
      });
    });
  }
}

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useAtom } from 'jotai';

import { persistFireBaseToken } from '@/api/fetch/marketplace-api';
import { SendUserDeviceConfigRequest } from '@/api/gen-protos/users_pb';
import { UserDataAtom } from '@/modules/core/store/grpc-free-store';
import { environment } from 'environment';

const PersistToken = (token: string, kcid: string) => {
  const request = new SendUserDeviceConfigRequest();
  // @ts-ignore
  request.setDeviceid(window.sessionStorage.getItem('SessionID'));
  request.setUserid(kcid || '');
  request.setDeviceplatform('web');
  request.setDevicetoken(token);
  persistFireBaseToken(request).then(
    () => {},
    (reason) => {
      // @ts-ignore
      throw new Error('error persisting firebase token', reason);
    }
  );
};
const FireBase = () => {
  const [userData] = useAtom(UserDataAtom);

  const credentials = {
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    databaseURL: environment.databaseURL,
    projectId: environment.projectId,
    storageBucket: environment.storageBucket,
    messagingSenderId: environment.messagingSenderId,
    appId: environment.appId,
    measurementId: environment.measurementId,
  };
  const app = initializeApp(credentials);

  const messaging = getMessaging(app);
  const requestForToken = async () => {
    const status = await Notification.requestPermission();
    if (
      status &&
      status === 'granted' &&
      localStorage.getItem('push-token') === null &&
      userData &&
      userData.kcid !== undefined
    ) {
      // @ts-ignore
      getToken(messaging, {
        vapidKey: environment.vapidKey,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            localStorage.setItem('push-token', currentToken);
            await PersistToken(currentToken, userData?.kcid || '');
          } else {
            throw new Error(
              'No registration token available. Request permission to generate one.'
            );
          }
        })
        .catch((err) => {
          throw new Error(`An error occurred while retrieving token.${err}`);
        });
    }
  };
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
  const onMessageListener = () =>
    new Promise((resolve) => {
      // @ts-ignore
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  return { onMessageListener, requestForToken };
};

export default FireBase;

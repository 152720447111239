import React from 'react';

import { Transition } from '@headlessui/react';

const ShowToRight = ({
  children,
  isShowing,
}: {
  children: JSX.Element | JSX.Element[];
  isShowing: boolean;
}) => (
  <Transition
    as={React.Fragment}
    show={isShowing}
    enter="transition-all duration-200"
    enterFrom="-translate-x-1/4 opacity-0 scale-x-90"
    enterTo="translate-x-0 opacity-100 scale-x-100"
    leave="transition-all duration-125"
    leaveFrom="translate-x-0 opacity-100 scale-x-100"
    leaveTo="-translate-x-1/4 opacity-0 scale-x-90"
  >
    {children}
  </Transition>
);

export default ShowToRight;

const FrequentlySearchedKeywordsStrip = () => (
  <div className="pointer-events-auto space-y-2">
    <span className="text-sm font-semibold text-neutral-400">
      Les mots clés les plus rechechées sur tayara
    </span>
    <div className="flex flex-wrap gap-2.5">
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        Ordinateur portable 16 RAM
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        BMW
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        vélo
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        mazda tout options
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        PS5 neuf
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        samsung occasion
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        iphone pas cher
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        maison a louer ain zaghouen
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        emploi
      </button>
      <button className="badge bg-neutral-100 text-neutral-500 !border !border-neutral-300 !text-xs !p-3 hover:bg-neutral-200">
        MacBook Pro 16 pouces M1 Pro chip
      </button>
    </div>
  </div>
);

export default FrequentlySearchedKeywordsStrip;

import { Suspense, SuspenseProps } from 'react';

import { useBoolean, useEffectOnce } from 'usehooks-ts';

export interface CSRLazyInitProps {
  source: string;
  delay?: number;
}

export const CSRLazy = (props: SuspenseProps) => {
  const { value: isSSR, setFalse: setNotSSR } = useBoolean(true);
  useEffectOnce(setNotSSR);

  return (
    <>
      {!isSSR ? (
        <Suspense fallback={props.fallback}>{props.children}</Suspense>
      ) : (
        props.fallback
      )}
    </>
  );
};

import { useState } from 'react';

import { useEffectOnce, useLocalStorage } from 'usehooks-ts';

const LOCAL_STORAGE_KEY = 'user.search_history';
const HISTORY_MAX_LENGTH = 20;

const updateHistory = (updateFn: Function, h: string[], query: string) => {
  const history = h;
  const index = history.indexOf(query);
  // If the query already exists in the history, bring it forward
  if (index > -1) {
    history.splice(index, 1);
    history.unshift(query);
    updateFn(history);
    return;
  }
  // If the history doesn't exist prepend it to the array
  history.unshift(query);
  // If hisotry length is longer than max allowed hisotry clear
  if (history.length > HISTORY_MAX_LENGTH) {
    history.pop();
  }
  updateFn(history);
};

const useSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [localStorageHistory, updateFn] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY,
    []
  );

  useEffectOnce(() => {
    setSearchHistory(localStorageHistory);
  });

  const prependSearchQuery = (searchQuery: string) => {
    if (searchQuery !== undefined && searchQuery !== '') {
      updateHistory(updateFn, [...localStorageHistory], searchQuery);
      setSearchHistory(localStorageHistory);
    }
  };

  return { searchHistory, prependSearchQuery };
};

export default useSearchHistory;

// eslint-disable @typescript-eslint/prefer-destructuring
// eslint-disable @typescript-eslint/no-use-before-define

import { useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';

import {
  ChangePasswordService,
  DeleteAccount,
  login,
} from '@/api/fetch/auth-service';
import { AuthService } from '@/api/fetch/authenticate-api';
import { updateUser } from '@/api/fetch/marketplace-api';
import {
  ChangePasswordRequestGRPC,
  DeleteUserDataRequest,
  LoginRequest,
} from '@/api/gen-protos/auth_pb';
import {
  NewUserByIDRequest,
  UserResponse,
  UserState,
} from '@/api/gen-protos/users_pb';
import { getProductTypeName } from '@/lib/helpers';
import { useAdsInfinite } from '@/lib/hooks/useInfiniteAdsList';
import { ErorrsAtom, SuccessAtom } from '@/lib/store';
import useLoginHook from '@/modules/auth/hooks/useLoginHook';
import useTransactions from '@/modules/profile/hooks/useTransactions';

import {
  Filter,
  GetSearchesRequest,
  RangeAdParams,
} from '../../../api/elasticsearch/models';
import {
  ListSearchVersion,
  DeleteSaveSearchApi,
} from '../../../api/fetch/save-search-api';
import { User } from '../../../models/user';
import { UserDataAtom, AuthTokenAtom } from '../store/grpc-free-store';
import { searchedListingsAtom } from '../store/store';
import { genSearchLink, uploadImages } from '../utils/utils';

type PasswordTabType = {
  password: string;
  repassword: string;
  oldPassword: string;
};
const useProfile = () => {
  const [, setSearchedListingsStore] = useAtom(searchedListingsAtom);

  const [userData, setUserData] = useAtom(UserDataAtom);
  const { balance, transactionList, expire } = useTransactions();
  const [authToken] = useAtom(AuthTokenAtom);
  const [, updateErrorsAtom] = useAtom(ErorrsAtom);
  const [, updateSuccessAtom] = useAtom(SuccessAtom);
  const { logout } = useLoginHook();
  const [userState, setUserState] = useState<any>({
    fullname: userData?.fullname,
    email: userData?.email,
    phone: userData?.phonenumber,
    kcid: userData?.kcid,
    shopbackgroundimage: userData?.shopBackground,
    usertype: userData?.userType,
    username: userData?.username,
  });
  const [passwordTab, setPasswordTab] = useState<PasswordTabType>({
    password: '',
    repassword: '',
    oldPassword: '',
  });
  const [savedSearchList, setSavedSearchList] = useState<
    {
      title: string;
      searchParams: string[];

      viewResult: () => void;
      deleteAlert: () => void;
    }[]
  >([]);
  const [adsListData, countAds, loadMoreAdsList] = useAdsInfinite({
    userId: userData.kcid || '',
    isPublic: false,
  });
  const authService: AuthService = new AuthService();
  const { push } = useRouter();

  const updateErrors = ({
    message,
    tabName,
  }: {
    message: string;
    tabName: string;
  }) => updateErrorsAtom({ message, tabName, type: 'set' });

  const updateSuccessMessage = ({
    message,
    tabName,
  }: {
    message: string;
    tabName: string;
  }) => updateSuccessAtom({ message, tabName, type: 'set' });

  const updateUserToServer = (imageurl?: string) => {
    updateErrors({ message: '', tabName: 'profile_details' });
    const {
      fullname,
      email,
      kcid,
      address,
      advantagesList,
      avatar,
      categoryid,
      phone,
      tokenbalance,
      description,
      url,
      usertype,
      shopbackgroundimage,
    } = userState;

    const newUserByIDRequest = new NewUserByIDRequest();
    const userstate = new UserState();

    newUserByIDRequest.setKcid(kcid);
    newUserByIDRequest.setUsertype(usertype);
    userstate.setEmail(email);

    userstate.setCategoryid(categoryid);
    userstate.setFullname(fullname);
    userstate.setAddress(address);
    userstate.setPhonenumber(phone);
    userstate.setAdvantagesList(advantagesList);
    userstate.setDescription(description);
    userstate.setUrl(url);
    userstate.setTokenbalance(tokenbalance);
    userstate.setAvatar(imageurl || avatar);
    userstate.setAdvantagesList(advantagesList);
    newUserByIDRequest.setNewuserstate(userstate);
    newUserByIDRequest.setShopbackgroundimage(shopbackgroundimage);
    newUserByIDRequest.setEnabled(true);

    updateUser(authToken, newUserByIDRequest)
      .then((res: UserResponse) => {
        const userFormat: User = {
          avatar: res.getUser()?.getCurrentstate()?.getAvatar(),
          kcid: res.getUser()?.getKcid(),
          productType: res.getUser()?.getProducttype(),
          userType: res.getUser()?.getUsertype(),
          phonenumber: res.getUser()?.getCurrentstate()?.getPhonenumber(),
          shopBackground: res.getUser()?.getShopbackgroundimage(),
          fullname: res.getUser()?.getCurrentstate()?.getFullname(),
          email: res.getUser()?.getCurrentstate()?.getEmail(),
        };
        setUserData(userFormat);
        updateSuccessMessage({
          message: ' mise à jour a été effectuée avec success',
          tabName: 'profile_details',
        });
      })
      .catch((err) => {
        updateErrors({ message: err, tabName: 'profile_details' });
      });
  };
  const onClickUpdateUserData = async () => {
    const { email } = userState;

    const updatedEmail = email !== userData?.email;
    if (updatedEmail) {
      authService
        .updateUserEmail(email, authToken)
        .then(() => {
          updateUserToServer();
        })
        .catch((err) =>
          updateErrors({ message: err, tabName: 'profile_details' })
        );
    } else {
      updateUserToServer();
    }
  };

  const updatePassword = async () => {
    const changePasswordRequest = new ChangePasswordRequestGRPC();

    updateErrors({ message: '', tabName: 'profile_security' });

    const { password, repassword, oldPassword } = passwordTab;
    if (!password || !repassword) {
      updateErrors({
        message: 'veuillez remplir tous les champs',
        tabName: 'profile_security',
      });
      return;
    }
    if (password !== repassword) {
      updateErrors({
        message: 'les mots de passe saisis ne sont pas identiques',
        tabName: 'profile_security',
      });
    }
    if (userData.phonenumber) {
      const req = new LoginRequest();
      req.setPassword(oldPassword);
      req.setUsername(userData.phonenumber);

      login(req)
        .then(async (_res) => {
          changePasswordRequest.setPassword(repassword);
          changePasswordRequest.setUserid(userData.kcid || '');
          ChangePasswordService(changePasswordRequest)
            .then((_r) => {
              updateSuccessMessage({
                message: ' mise à jour a été effectuée avec success',
                tabName: 'profile_security',
              });
            })

            .catch(() => {
              updateErrors({
                tabName: 'profile_security',
                message: 'Oups ! Veuillez réessayer plus tard!',
              });
            });
        })
        .catch(() => {
          updateErrors({
            tabName: 'profile_security',
            message: 'l`ancien mot de pass est incorrect',
          });
        });
    }
  };

  const updateUserState = (name: string, value: string) => {
    setUserState((p: any) => ({ ...p, [name]: value }));
  };

  const updatePasswordTab = (name: string, value: string) => {
    setPasswordTab((p: any) => ({ ...p, [name]: value }));
  };

  const selectImage = (e: any) => {
    const files = Array.from(e.target.files);

    uploadImages(files as any, authToken)
      .then((data) => data.json())
      .then((imagesUrl: any) => {
        if (imagesUrl?.message) {
          updateErrors({
            tabName: 'profile_images',
            message: 'Erreur, veuillez réessayer plus tard',
          });
          return;
        }

        updateUserState('avatar', imagesUrl[0]);

        updateUserToServer(imagesUrl[0]);
      });
  };

  const updateUrlParams = (filter: Filter, searchQuery?: string) => {
    let queryParamsObject: any = {};

    if (searchQuery !== undefined && searchQuery !== '') {
      queryParamsObject.q = searchQuery;
    }

    if (filter.governorate !== undefined)
      queryParamsObject.governorate = filter.governorate;
    if (filter.delegation !== undefined) {
      queryParamsObject.delegation = filter.delegation.join(',');
    }
    if (filter.category_name !== undefined)
      queryParamsObject.category = filter.category_name;

    if (filter.sub_category_name !== undefined)
      queryParamsObject.subCategory = filter.sub_category_name;

    if (filter.min_price !== undefined && filter.min_price > 0)
      queryParamsObject.minPrice = filter.min_price;
    if (filter.max_price !== undefined && filter.max_price > 0)
      queryParamsObject.maxPrice = filter.max_price;
    if (filter.product_type !== undefined) {
      let productTypeNameUri = '';
      filter.product_type.forEach((productType, i) => {
        const productTypeName = getProductTypeName(productType);
        productTypeNameUri += (i > 0 ? ',' : '') + productTypeName;
      });

      queryParamsObject.productType = productTypeNameUri;
    }

    if (filter.ad_params !== undefined)
      queryParamsObject = { ...queryParamsObject, ...filter.ad_params };

    if (filter.range_ad_params !== undefined)
      Object.entries(filter.range_ad_params).forEach((el) => {
        const selectedMin = el[1].min;
        const selectedMax = el[1].max;

        if (!isEmpty(selectedMin))
          queryParamsObject[`min${el[0]}`] = selectedMin;
        if (!isEmpty(selectedMax))
          queryParamsObject[`max${el[0]}`] = selectedMax;
      });

    setSearchedListingsStore({
      newHits: [],
      type: 'init',
      query: '',
      sort: '',
      meilisearchFilterList: [],
      elasticsearchFilterList: {},
      premiumHits: [],
      totalHitsCount: 0,
      isDefaultListings: true,
      isSSR: false,
    });

    const searchLink = genSearchLink(queryParamsObject);
    delete queryParamsObject.q;
    delete queryParamsObject.governorate;
    delete queryParamsObject.delegation;
    delete queryParamsObject.category;
    delete queryParamsObject.subCategory;
    delete queryParamsObject.productType;

    push({
      pathname: searchLink,
      query: queryParamsObject,
    });
  };

  const getListAlert = () => {
    if (userData.kcid) {
      const request: GetSearchesRequest = {
        user_id: userData.kcid,
      };

      ListSearchVersion(request).then((d) => {
        if (!isEmpty(d)) {
          setSavedSearchList(
            d.searches.map((sl) => {
              const query = sl.elasticsearch_filter?.filter;
              let filterQuery = [];
              if (!isEmpty(query)) {
                const firstFourFilters: any = [
                  query!.governorate &&
                    `Ville:&nbsp;<strong> ${decodeURIComponent(
                      query!.governorate as string
                    )}</strong>`,
                  !isEmpty(query!.delegation) &&
                    `Délégation:&nbsp;<strong>${decodeURIComponent(
                      query!.delegation!.join(',')
                    )}</strong>`,

                  query!.category_name &&
                    `Catégorie:&nbsp;<strong> ${decodeURIComponent(
                      query!.category_name as string
                    )}</strong>`,
                  query!.sub_category_name &&
                    `Sous-catégorie:&nbsp;<strong> ${decodeURIComponent(
                      query!.sub_category_name as string
                    )}</strong>`,
                  !isEmpty(query!.product_type) &&
                    `État de produit:&nbsp;<strong>${decodeURIComponent(
                      query!.product_type!.join(',') as string
                    )}</strong>`,
                  query!.min_price &&
                    `Prix min:&nbsp;<strong>${decodeURIComponent(
                      query!.min_price.toString() as string
                    )}</strong>`,
                  query!.max_price &&
                    `Prix max:&nbsp;<strong>${decodeURIComponent(
                      query!.max_price.toString() as string
                    )}</strong>`,
                ].filter((f) => f);
                const filterAdparams: any = [];
                (
                  Object.entries(query!.ad_params!) as [string, string][]
                ).forEach(([key, value]) => {
                  filterAdparams.push(
                    `${key}:&nbsp;<strong>${decodeURIComponent(
                      value as string
                    )}</strong>`
                  );
                });
                const filterAdRangeparams: any = [];
                (
                  Object.entries(query!.range_ad_params!) as [
                    string,
                    RangeAdParams
                  ][]
                ).forEach(([key, value]) => {
                  filterAdRangeparams.push(
                    `${key}-min:&nbsp;<strong>${decodeURIComponent(
                      value.min as string
                    )}</strong>`,
                    `${key}-max:&nbsp;<strong>${decodeURIComponent(
                      value.max as string
                    )}</strong>`
                  );
                });
                filterQuery = firstFourFilters.concat(
                  filterAdparams,
                  filterAdRangeparams
                );
              }
              return {
                title: sl.name || '',
                searchParams: filterQuery,
                viewResult: () => updateUrlParams(query!, sl.name),
                deleteAlert: () => {
                  if (userData.kcid && sl.id) {
                    DeleteSaveSearchApi({
                      search_id: sl.id,
                      user_id: userData.kcid,
                    }).then(() => {
                      getListAlert();
                    });
                  }
                },
              };
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (userData.kcid) getListAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.kcid]);

  const deleteAccount = (param: string) => {
    const req = new DeleteUserDataRequest();
    req.setDeleteOrEnable(param);
    req.setToken(authToken);
    DeleteAccount(req).then(() => {
      logout();
    });
  };
  return {
    adsListData,
    loadMoreAdsList,
    balance,
    expire,
    transactionList,
    userData,
    updateUserState,
    userState,
    onClickUpdateUserData,

    passwordTab,
    updatePasswordTab,
    updatePassword,
    selectImage,
    countAds,
    deleteAccount,
    savedSearchList,
  };
};

export default useProfile;

import { Value } from '@/lib/constants';

export const MEILI_FETCH_LIMIT = 30;

// const MEILI_URL = 'http://34.159.243.119:7700'
export const MEILI_URL =
  process.env.NODE_ENV === 'production'
    ? typeof window === 'undefined'
      ? 'http://meilisearch.caravel-tayara.svc.cluster.local:7700'
      : '/api/search'
    : '/api/search';

export const INDEX_ADS = 'ads';
export const API_KEY = '9HiOJAa2YwzK7j4mfC1C';

export interface MeilisearchHit {
  _id: string;
  title: string;
  images: string[];
  imgLoad: Value<string>;
  shortDescription: string;
  price: number;

  userphonenumber: Value<string>;
  delegation: Value<string>;
  governorate: Value<string>;
  timestampsortscore: number;
  versionchangetype: number;
  subcategoryid: string;
  level: number;
  user_name: string;
  user_type: number;
  shopavatar: Value<string>;
  ShopLabel: string;
  producttype: number;
}

export interface MeilisearchRequestBody {
  q: string;
  limit: number;
  offset: number;
  sort?: string | null;
  filter?: string[];
  shuffledLimit?: number;
}

export const fbQueryParamsKeysToIgnore = [
  'fbclid',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_campaign',
];

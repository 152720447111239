import { environment } from 'environment';

import {
  AddSearchRequest,
  CheckEqualSearchResponse,
  DeleteSearchRequest,
  GetSearchesReply,
  GetSearchesRequest,
  SaveSearchResponse,
} from '../elasticsearch/models';

export const newSaveSearchApi = async (
  saveSearchRequest: AddSearchRequest
): Promise<SaveSearchResponse> => {
  const response = await fetch(
    `${environment.saveSearchUrl}/rest/add_save_search`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saveSearchRequest),
    }
  );
  const json: SaveSearchResponse = await response.json();

  return json;
};

export const DeleteSaveSearchApi = async (
  saveSearchRequest: DeleteSearchRequest
): Promise<SaveSearchResponse> => {
  const response = await fetch(
    `${environment.saveSearchUrl}/rest/delete_save_search`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saveSearchRequest),
    }
  );
  const json: SaveSearchResponse = await response.json();

  return json;
};
export const ListSearchVersion = async (
  getSearchesRequest: GetSearchesRequest
): Promise<GetSearchesReply> => {
  const response = await fetch(
    `${environment.saveSearchUrl}/rest/get_list_save_search`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getSearchesRequest),
    }
  );
  const json = await response.json();

  return {
    searches: json.searches,
  };
};

export const verifySaveSearchApi = async (
  saveSearchRequest: AddSearchRequest
): Promise<CheckEqualSearchResponse> => {
  // console.log('AddSearchRequest', saveSearchRequest);

  const response = await fetch(
    `${environment.saveSearchUrl}/rest/verify_save_search`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saveSearchRequest),
    }
  );
  const json: CheckEqualSearchResponse = await response.json();

  return json;
};

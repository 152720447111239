import { Value } from '@/lib/constants';

export const ELASTIC_FETCH_LIMIT = 30;

export const ELASTIC_URL = 'https://www.tayara.tn/api/search';

export const INDEX_ADS = 'ads-prod';

export interface ElasticSearchHit {
  _id: string;
  title: string;
  images: string[];
  imgLoad: Value<string>;
  shortDescription: string;
  price: number;
  userphonenumber: Value<string>;
  delegation: Value<string>;
  governorate: Value<string>;
  timestampsortscore: number;
  versionchangetype: number;
  subcategoryid: string;
  level: number;
  user_name: string;
  user_type: number;
  shopavatar: Value<string>;
  ShopLabel: string;
}

export interface ElasticSearchRequestBody {
  q: string;
  limit: number;
  offset: number;
  sort?: string[] | null;
  filter?: string[];
  shuffledLimit?: number;
}

export const fbQueryParamsKeysToIgnore = [
  'fbclid',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_campaign',
];

export interface RangeAdParamsRequestObject {
  min: string;
  max: string;
}

export interface FilterRequestObject {
  categoryId: string;
  subCategoryId: string;
  adParamsMap: { [x: string]: string };
  rangeAdParamsMap: { [x: string]: RangeAdParamsRequestObject };
  governorate: string;
  delegation: string[];
  minPrice: number;
  maxPrice: number;
  productTypeList: number[];
  level?: number;
  state: number;
  userid?: string;
  ids?: string[];
}

export interface SearchRequestObject {
  query: string;
  offset: number;
  limit: number;
  sort: number;
  filter: FilterRequestObject;
  afterTimestamp?: number;
}
export interface CheckEqualSearchResponse {
  equal: boolean;
}

export interface AddSearchRequest {
  user_id: string;
  name: string;
  elasticsearch_filter?: SearchRequest;
}

export interface SaveSearchResponse {
  message: string;
}

export interface GetSearchesRequest {
  user_id: string;
}

export interface SearchItem {
  id: string;
  name: string;
  elasticsearch_filter?: SearchRequest;
  active: boolean;
}

export interface GetSearchesReply {
  searches: SearchItem[];
}

export interface DeleteSearchRequest {
  user_id: string;
  search_id: string;
}

export interface UpdateSearchRequest {
  user_id: string;
  search_id: string;
  active: boolean;
}

export interface RangeAdParams {
  min: string;
  max: string;
}

export interface Filter {
  category_id: string;
  sub_category_id: string;
  ad_params: { [key: string]: string };
  range_ad_params: { [key: string]: RangeAdParams };
  governorate: string;
  delegation: string[];
  min_price: number;
  max_price: number;
  product_type: number[];
  level: number;
  state: number;
  user_id: string;
  ids: string[];
  category_name: string;
  sub_category_name: string;
}
export interface SearchRequest {
  query?: string;
  offset?: number;
  limit?: number;
  filter?: Filter;
}

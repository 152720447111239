import { IconType } from 'react-icons';

const TokenHistory: IconType = ({ size, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="TiTokenHistory"
    data-name="Ti TokenHistory"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <title>t-token-history</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10,0A10,10,0,1,1,.06,11.11a1,1,0,0,1,1-1.11h0a1,1,0,0,1,1,.88A8,8,0,0,0,10.23,18,8.1,8.1,0,0,0,18,10.23,8,8,0,0,0,3.39,5.5H4.62a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H1.07A1.07,1.07,0,0,1,0,6.43V2.5a1,1,0,0,1,1-1H1a1,1,0,0,1,1,1V4A10,10,0,0,1,10,0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.16,14.3c0,.13,0,.45-.1.53l-.27.17a3.62,3.62,0,0,1-2,.56c-1.52,0-2.27-.58-2.27-1.73,0-.35,0-.86,0-1.56s0-1.22,0-1.56-.14-.34-.41-.34H8.12S7,10.5,6.92,10.3a4.65,4.65,0,0,1,0-2c.07-.15.91-.18,1.25-.2a.41.41,0,0,0,.34-.31,3.75,3.75,0,0,0,0-.67c0-.49,0-.67,0-.7,0-.62.06-.92.18-.92l.6,0,.69,0,1.15,0c.13,0,.19.06.19.13s0,.53-.06,1,0,.75,0,1,.07.37.23.39a7.64,7.64,0,0,0,.82.1,3,3,0,0,1,.6,0c.08,0,.24,0,.24.28a2,2,0,0,0,0,.25c0,.24,0,.36,0,.48s0,.37,0,.61a1.35,1.35,0,0,1,0,.45.2.2,0,0,1-.22.16H12l-.59.06c-.09,0-.12.1-.12.34v.56c0,.25,0,.43,0,.56a2,2,0,0,0,.14.9c.15.22.3.3.72.3a1.22,1.22,0,0,0,.37-.05L13,13a.15.15,0,0,1,.17.17,4,4,0,0,0,0,.49C13.18,13.91,13.16,14.14,13.16,14.3Z"
    />
  </svg>
);
export default TokenHistory;

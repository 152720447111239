import { useRouter } from 'next/router';
import { HiTag } from 'react-icons/hi';
import { IoIosHome } from 'react-icons/io';

const useBreadcrumbs = () => {
  const { query, asPath } = useRouter();

  const res = [
    'home',
    query.category,
    query.subCategory,
    query.governorate,
    query.delegation,
    query.slug,
  ]
    .filter((e) => !!e)
    .map((e) => {
      if (e === 'home') {
        return {
          label: 'Accueil',
          link: '/',
          icon: IoIosHome,
        };
      }
      return {
        label: (e as string).slice(0, (e as string).lastIndexOf('_')),
        link: asPath,
        icon: HiTag,
      };
    });

  return res;
};
export default useBreadcrumbs;

import React, { FunctionComponent, useEffect, useMemo } from 'react';

import { useAtom } from 'jotai';
import { nanoid } from 'nanoid';

import { isTransitionningAtom, LoadHeavyContentAtom } from '@/lib/store';

export interface GoogleAdMapping {
  [mapping: number]: number[];
}

export interface GoogleAdType {
  id?: string;
  slot: string;
  sizes: (number[] | 'fluid')[];
  mapping: GoogleAdMapping;
}

export interface GoogleAdProps extends React.HTMLAttributes<HTMLDivElement> {
  ad: GoogleAdType;
}

const GoogleAd: FunctionComponent<GoogleAdProps> = ({
  ad,
  ...props
}: GoogleAdProps) => {
  const [isTransitioning] = useAtom(isTransitionningAtom);
  const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);
  const id = useMemo(() => ad.id || nanoid(), [ad.id]);

  useEffect(() => {
    let adBanner: any;

    if (loadHeavyContent && !isTransitioning && typeof window !== undefined) {
      const { googletag } = window;
      googletag.cmd.push(async () => {
        await new Promise((res) => {
          const i = setInterval(() => {
            if (
              googletag.sizeMapping &&
              googletag.defineSlot &&
              googletag.enableServices &&
              googletag.pubads
            ) {
              clearInterval(i);
              res(undefined);
            }
          }, 100);
        });

        const adMapping = googletag.sizeMapping();
        Object.keys(ad.mapping).forEach((breakpoint) => {
          adMapping.addSize(
            [Number(breakpoint), 0],
            [ad.mapping[parseInt(breakpoint, 10)]]
          );
        });
        const builtMapping = adMapping.build();

        const pubads = googletag.pubads();

        pubads.collapseEmptyDivs();
        adBanner = googletag
          ?.defineSlot(ad.slot, ad.sizes, id)
          ?.defineSizeMapping(builtMapping)
          ?.addService(pubads);
        googletag?.enableServices();
      });

      googletag.cmd.push(async () => {
        await new Promise((res) => {
          const i = setInterval(() => {
            if (googletag.display) {
              clearInterval(i);
              res(undefined);
            }
          }, 100);
        });

        googletag.display(id);
      });
    }
    return () => {
      if (adBanner && adBanner.destroySlots) {
        adBanner.destroySlots();
      }
    };
  }, [ad, id, isTransitioning, loadHeavyContent]);

  return loadHeavyContent ? <div id={id} {...props} /> : null;
};

export default GoogleAd;

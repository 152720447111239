import { createRef, RefObject, useEffect, useState } from 'react';

interface Rect {
  width: number | undefined;
}
function useResizeObserver<T extends HTMLElement = HTMLElement>(): [
  RefObject<T>,
  Rect
] {
  const ref = createRef<T>();
  const [width, setWidth] = useState<number>();

  const updateEntry = ([ent]: ResizeObserverEntry[]): void => {
    setWidth(ent?.contentRect?.width);
  };

  useEffect(() => {
    const hasROSupport = !!window.ResizeObserver;

    if (!hasROSupport || !ref) return undefined;

    const observer = new ResizeObserver(updateEntry);

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [ref, { width }];
}

export default useResizeObserver;

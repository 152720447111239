export const APP_CONFIG = {
  site_name: 'Tayara',
  title: 'Tayara | Achetez et vendre gratuitement près de chez vous',
  description: `Tayara, achat et vente gratuitement des voitures, de l'immobilier, des smartphones, des ordinateurs, des électroménagers et plus.`,
  locale: 'fr-FR',
  basePath: 'https://tayara.tn',
  standardWidth: {
    xs: 164,
    sm: 210,
    md: 210,
    lg: 210,
    xl: 210,
    '2xl': 210,
    default: 210,
  },
  standardHeight: {
    xs: 300,
    sm: 350,
    md: 350,
    lg: 350,
    xl: 350,
    '2xl': 350,
    default: 350,
  },
  VISITED_HISTORY_MAX_LENGTH: 25,
};

import { IconType } from 'react-icons';

const TayaraToken: IconType = ({ size = 20, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="tayara-token"
    data-name="tayara token"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>t-token</title>
    <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm3.27,16.63c0,.17,0,.53-.12.63l-.31.2a4.29,4.29,0,0,1-2.34.66q-2.67,0-2.67-2c0-.4,0-1,.05-1.83s0-1.43,0-1.83S9.76,12,9.45,12H9.34c-.06,0-1.37.16-1.41-.08A5.64,5.64,0,0,1,8,9.58c.09-.18,1.08-.21,1.48-.23A.48.48,0,0,0,9.84,9a3.39,3.39,0,0,0,0-.78c0-.59,0-.79,0-.84,0-.72.07-1.07.21-1.07l.71,0,.81,0c.33,0,.68,0,1.34.06.16,0,.23.06.23.15,0,.25,0,.62-.06,1.12S13,8.58,13,8.82s.09.43.27.45a6.43,6.43,0,0,0,1,.12,4.1,4.1,0,0,1,.7,0c.1,0,.29,0,.29.34a2.83,2.83,0,0,0,0,.29c0,.28,0,.42,0,.56s0,.44,0,.72a1.57,1.57,0,0,1,0,.53.22.22,0,0,1-.26.19l-.48,0h-.61l-.7.08c-.09,0-.14.12-.14.4v.65c0,.29,0,.51,0,.66a2.47,2.47,0,0,0,.18,1.07c.17.26.35.34.84.34a1.65,1.65,0,0,0,.44-.05,4.21,4.21,0,0,1,.6-.11.18.18,0,0,1,.2.21,2.72,2.72,0,0,0,0,.57C15.29,16.18,15.27,16.45,15.27,16.63Z" />
  </svg>
);
export default TayaraToken;

import { useEffect, useState } from 'react';

import { useAtom } from 'jotai';

import { getUserBalanceTransactions } from '@/api/fetch/marketplace-api';
import { GetAccountTransactionsRequest } from '@/api/gen-protos/users_pb';
import {
  GetUserTransactionsBalanceResponse,
  TransfertTransaction,
} from '@/api/gen-protos/wallets_pb';
import { mapGetUserTransactionsBalanceResponseToItransaction } from '@/modules/core/mappings';
import {
  AuthTokenAtom,
  UserDataAtom,
} from '@/modules/core/store/grpc-free-store';
import { Itransaction } from '@/modules/core/types';

const useTransactions = () => {
  const [authToken] = useAtom(AuthTokenAtom);
  const [userData] = useAtom(UserDataAtom);
  const [transactionList, setTransactionList] = useState<Itransaction[]>([]);
  const [balance, setBalance] = useState<number>(100);
  const [expire, setExpire] = useState<string | undefined>('');

  useEffect(() => {
    const arr: TransfertTransaction[] = [];
    if (!userData?.kcid) return;
    if (!authToken) return;
    const userbalanceAndTransactions = new GetAccountTransactionsRequest();
    userbalanceAndTransactions.setUserid(userData?.kcid);
    getUserBalanceTransactions(authToken, userbalanceAndTransactions).then(
      (transactions: GetUserTransactionsBalanceResponse) => {
        setBalance(parseInt(transactions.getBalance(), 10));
        setTransactionList(
          mapGetUserTransactionsBalanceResponseToItransaction(transactions)
        );
        // eslint-disable-next-line array-callback-return
        transactions.getTransactionsList().map((t) => {
          if (t.getSrcAccountId() === 'planche@caravel') {
            arr.push(t);
          }
        });
        setExpire(arr[0]?.getDescription()?.substring(0, 20));
      },
      (error) => {
        throw new Error(`Connection impossible: ${error}`);
      }
    );
  }, [userData, authToken]);

  return { balance, transactionList, expire };
};

export default useTransactions;

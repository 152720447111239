import { useRef } from 'react';

import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { MdCategory } from 'react-icons/md';
import { useHover } from 'usehooks-ts';

import { CategoryHelper } from '@/lib/helpers';

export interface ICategory {
  id: string;
  label: string;
  icon: React.ElementType<any>;
  link: string;
  linkFr: string;
}
interface KeyCategoryItemProps {
  categoryData: ICategory;
  handleCategoryClick: (selectedCategoryLink: string) => void;
  subCategories: CategoryHelper[];
}

const KeyCategoryItem = ({
  categoryData,
  handleCategoryClick,
  subCategories,
}: KeyCategoryItemProps) => {
  const { label, link, linkFr, icon: Icon = MdCategory } = categoryData;

  const panelRef = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<HTMLAnchorElement>(null);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const panelHasHover = useHover(panelRef);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const categoryHasHover = useHover(categoryRef);

  return (
    <>
      <Link
        href={{
          pathname: `/ads${linkFr ? `/c/${linkFr}` : ''}`,
        }}
        passHref
        ref={categoryRef}
        className="flex flex-col items-center gap-y-1 group cursor-pointer"
        onClick={() => {
          if (link !== '') {
            handleCategoryClick(link);
          }
        }}
      >
        <div
          className={`rounded-2xl p-1 md:p-3 lg:p-3 flex flex-col items-center justify-center gap-1 md:gap-2 w-full border-[1px] border-gray-300 bg-white group-hover:bg-primary/5 text-primary group-hover:text-primary`}
        >
          <Icon size={34} />
          <div className="w-full text-2xs group-hover:text-primary md:hidden text-center overflow-hidden whitespace-nowrap overflow-ellipsis">
            {label}
          </div>
        </div>
        <span className="text-2xs text-neutral-400 font-bold relative group-hover:text-neutral-700 hidden md:block">
          <span className="absolute top-0 left-1/2 -translate-x-1/2 min-w-max">
            {label}
          </span>
        </span>
      </Link>
      <Popover key={link} className="relative z-50" ref={panelRef}>
        <Transition
          show={false && (panelHasHover || categoryHasHover)} // Remove the `false &` when sub categories click behaviour is fixed
          as="div"
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            as="div"
            className="absolute rounded-2xl bg-white p-3 shadow-2xl -translate-x-1/2 left-1/2 z-50"
          >
            {/* arrow up */}
            <div className="border-r-8 border-l-8 border-b-8 border-transparent border-b-white w-0 absolute top-0 right-0 left-0 mx-auto -translate-y-full"></div>
            <h3 className="font-black text-red-400 mb-2 flex items-center gap-x-2">
              <span>
                <Icon />
              </span>
              <span>{label}</span>
            </h3>
            <ul className="w-full ">
              {subCategories.map((cat) => (
                <li key={cat.id} className="">
                  <Link
                    href={{
                      pathname: `/ads/${
                        linkFr ? `/c/${linkFr}/${cat.name}` : ''
                      }`,
                    }}
                    passHref
                    className="text-sm text-neutral-600 font-bold block hover:text-neutral-800 hover:bg-neutral-50 w-48 p-2 rounded-lg"
                  >
                    {cat.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default KeyCategoryItem;

import { rudderstackTrackEvent } from '@/api/rudderstack/client';
import { IListingItem } from '@/modules/core/types';

import RudderEvent, { CarouselEvents } from '../event-types';
import { CarouselType } from '../types';

interface IClassifiedClickPayload {
  carousel_type: CarouselType;
  classified_details: IListingItem;
  classified_position: number;
}
interface IScrollPayload {
  scroll_percentage: number;
  carousel_type: CarouselType;
  relative_data: unknown;
}

// Generic  tracking function, untyped very loose
const useRudderTrack = () => {
  const rudderTrack = (event: RudderEvent, payload: unknown) => {
    rudderstackTrackEvent(event, payload);
  };

  // Typed and specific tracking function
  // Tracks classifieds that exist in carousels
  const trackCarouselClassifiedClick = (
    type: CarouselEvents,
    payload: IClassifiedClickPayload
  ) => {
    rudderTrack(type, payload);
  };

  // Typed and specific tracking function
  // Tracks carousel scoll progress
  const trackCarouselScroll = (
    type: CarouselEvents,
    payload: IScrollPayload
  ) => {
    rudderTrack(type, payload);
  };

  return {
    trackCarouselScroll,
    rudderTrack,
    trackCarouselClassifiedClick,
  };
};

export default useRudderTrack;

import { Value } from '@/lib/constants';
import { IListingItem } from '@/modules/core/types';
import { NEXT_API_URL } from 'environment';

import { SearchRequestObject } from '../elasticsearch/models';

const updateImageUrl = (imageUrl: string): string => {
    const updatedUrl = imageUrl.replace(
        'https://storage.googleapis.com/tayara-migration-yams-pro',
        'https://cdn.tayara.tn'
    );
    return updatedUrl;
};

export const searchElasticApi = async (
    searchRequest: Value<SearchRequestObject>,
    withPremium: boolean
): Promise<[[IListingItem[], number], [IListingItem[]]]> => {
    try {
        const response = await fetch(`${NEXT_API_URL}/marketplace/search-api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                searchRequest,
                withPremium,
            }),
        });

        if (!response.ok) {
            console.error(`Error: ${response.status} ${response.statusText}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const [[newHits, totalHitsCount], [newHitsPremium]] = data;

        const updatedNewHits = newHits.map((listingItem: IListingItem) => ({
            ...listingItem,
            images: listingItem.images.map(updateImageUrl),
        }));

        const updatedNewHitsPremium = newHitsPremium.map((listingItem: IListingItem) => ({
            ...listingItem,
            images: listingItem.images.map(updateImageUrl),
        }));

        return [[updatedNewHits, totalHitsCount], [updatedNewHitsPremium]];

    } catch (error) {
        console.error('Failed to fetch search results:', error);
        throw error;
    }
};

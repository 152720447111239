import { MutableRefObject } from 'react';

import { atom } from 'jotai';

import { getCategory } from '@/api/fetch/marketplace-api';
import { CategoryByIDRequest } from '@/api/gen-protos/categories_pb';
import { MEILI_FETCH_LIMIT } from '@/api/meilisearch/models';
import rudderstackApi from '@/api/rudderstack/client';
import { getDelegationsOfGovernorate, categories } from '@/lib/helpers';

import { LIMIT, SHOW_ALL_ADS } from '../../../lib/constants';
import { mapCategoryResponseToIParams } from '../mappings';
import { IListingItem, IParams } from '../types';

export interface SearchedListingsStore {
  hits: IListingItem[];
  premiumHits: IListingItem[];
  totalHitsCount: number;

  limit: number;
  offset: number;
  query: string;
  sort: string | null;
  elasticsearchFilterList: Filter;
  meilisearchFilterList: string[];
  isInitialSearch: boolean;
  isDefaultListings: boolean;
  pageNum: number;
  isSSR: boolean;
}

export interface SearchedListingsAction {
  type: 'init' | 'inc';
  newHits: IListingItem[];
  sort: string | null;
  meilisearchFilterList?: string[];
  elasticsearchFilterList?: Filter;

  totalHitsCount?: number;
  premiumHits?: IListingItem[];
  query?: string;
  isDefaultListings?: boolean;
  pageNum?: number;
  offset?: number;
  isSSR?: boolean;
}

const initSearchedListingsAtom = atom<SearchedListingsStore>({
  hits: [],
  premiumHits: [],
  totalHitsCount: 0,
  offset: 0,
  limit: MEILI_FETCH_LIMIT,
  query: '',
  sort: null,
  meilisearchFilterList: [],
  isInitialSearch: true,
  isDefaultListings: true,
  pageNum: 1,
  isSSR: false,
  elasticsearchFilterList: {},
});

// Meilisearch ads
export const searchedListingsAtom = atom(
  // Getter
  (get) => get(initSearchedListingsAtom),

  // Setter
  (_, set, action: SearchedListingsAction) => {
    switch (action.type) {
      case 'init':
        set(
          initSearchedListingsAtom,
          (prev: SearchedListingsStore): SearchedListingsStore => {
            const newMeilisearchFilterList =
              action.meilisearchFilterList !== undefined
                ? action.meilisearchFilterList
                : prev.meilisearchFilterList;
            const newElasticSearchFilterList =
              action.elasticsearchFilterList !== undefined
                ? action.elasticsearchFilterList
                : prev.elasticsearchFilterList;
            const newPremiumHits =
              action.premiumHits !== undefined
                ? action.premiumHits
                : prev.premiumHits;
            const isDefaultListings =
              action.isDefaultListings !== undefined
                ? action.isDefaultListings
                : false;
            const newTotalHitsCount =
              action.totalHitsCount !== undefined
                ? action.totalHitsCount
                : prev.totalHitsCount;
            const pageNum = action.pageNum !== undefined ? action.pageNum : 1;
            const offset = action.offset !== undefined ? action.offset : 0;
            const isSSR = action.isSSR !== undefined ? action.isSSR : false;

            const res = {
              ...prev,
              isInitialSearch: true,
              sort: action.sort,
              offset: offset + prev.limit,
              hits: action.newHits,
              query: action.query!,
              meilisearchFilterList: newMeilisearchFilterList,
              premiumHits: newPremiumHits,
              isDefaultListings,
              totalHitsCount: newTotalHitsCount,
              pageNum,
              isSSR,
              elasticsearchFilterList: newElasticSearchFilterList,
            } as SearchedListingsStore;
            return res;
          }
        );
        break;
      case 'inc':
        set(
          initSearchedListingsAtom,
          (prev: SearchedListingsStore): SearchedListingsStore => {
            const res = {
              ...prev,
              isInitialSearch: false,
              isSSR: false,
              offset: prev.offset + prev.limit,
              hits: [...prev.hits, ...action.newHits],
              pageNum: prev.pageNum + 1,
            } as SearchedListingsStore;
            return res;
          }
        );
        break;

      default:
        break;
    }
  }
);

export const goldenListingAtom = atom<IListingItem | null>(null);

export const SearchQueryAtom = atom('');

export const searchResultsTextRefAtom =
  atom<MutableRefObject<HTMLDivElement | null> | null>(null);

/* export interface ScrollPosition {
  old: number;
  current: number;
} */
export const OldScrollPositionAtom = atom(0);

export interface Filter {
  categoryName?: string;
  categoryId?: string;
  subCategoryName?: string;
  subCategoryIds?: string[];
  adParams?: any;
  rangeAdParams?: SelectedParamsRange;
  governorate?: string;
  delegation?: string[];
  minPrice?: number;
  maxPrice?: number;
  productType?: number[];
  afterTimestamp?: number;
}
export const FilterAtom = atom<Filter>({});

export interface TrackedAdCards {
  /** Ads that are still untracked */
  untracked: string[];

  /** Hashmap containing all the previously seen ads (tracked or not) */
  lookupTable: {
    [x: string]: boolean;
  };
}

export const InitTrackedAdCardsAtom = atom<TrackedAdCards>({
  lookupTable: {},
  untracked: [],
});

export const TrackedAdCardsAtom = atom(
  (get) => get(InitTrackedAdCardsAtom),
  (get, set, adId: string) => {
    const trackedAdCards = get(InitTrackedAdCardsAtom);
    const adNotTracked =
      trackedAdCards.lookupTable[adId] === undefined
        ? true
        : !trackedAdCards.lookupTable[adId];

    if (trackedAdCards.untracked.length > 20) {
      rudderstackApi.trackAdImpression([...trackedAdCards.untracked, adId]);
      set(InitTrackedAdCardsAtom, (prev) => ({
        lookupTable: {
          ...prev.lookupTable,
          [adId]: true,
        },
        untracked: [],
      }));
    } else if (adNotTracked) {
      set(InitTrackedAdCardsAtom, (prev) => ({
        lookupTable: {
          ...prev.lookupTable,
          [adId]: true,
        },
        untracked: [...prev.untracked, adId],
      }));
    }
  }
);

export interface CurrentAdCardInView {
  index: number;
  id: string;
}

export const CurrentAdCardInViewAtom = atom<CurrentAdCardInView | null>(null);

export interface SelectedParamsRangeValue {
  name: string;
  selectedMin?: string;
  selectedMax?: string;
}
export type SelectedParamsOptionsValue = string;

export type SelectedParamsRange = {
  [key: string]: SelectedParamsRangeValue;
};

export type SelectedParamsOptions = {
  [key: string]: SelectedParamsOptionsValue;
};

export type SelectedCategory = {
  id?: string;
  name?: string;
};

export type SelectedPrice = {
  min?: string;
  max?: string;
};

const selectedParamsRangeAtom = atom<SelectedParamsRange>({});
const selectedParamsMultiSelectAtom = atom<SelectedParamsOptions>({});
export const selectedSubcategoryAtom = atom<SelectedCategory>({});
export const ParamsListAtom = atom<IParams[]>([]);
export const SelectedCategoryAtom = atom<SelectedCategory>({});
export const searchAtom = atom<string>('');

export const initSelectedPriceAtom = atom<SelectedPrice>({});
export interface PriceRangeAction {
  type: 'set' | 'empty';
  data?: SelectedPrice;
}
export const SelectedPriceAtom = atom(
  (get) => get(initSelectedPriceAtom),
  (_, set, action: PriceRangeAction) => {
    if (action.type === 'set') {
      set(initSelectedPriceAtom, (prev: SelectedPrice) => {
        if (action.data!.max === undefined)
          return {
            ...prev,
            min: action.data!.min,
          };

        if (action.data!.min === undefined)
          return {
            ...prev,
            max: action.data!.max,
          };

        return {
          ...prev,
          max: action.data!.max,
          min: action.data!.min,
        };
      });
    } else {
      // if (action.type === 'empty')
      set(initSelectedPriceAtom, {});
    }
  }
);

export interface DelegationSelect {
  id: string;
  name: string;
}
export interface GovernorateSelect {
  id: string;
  name: string;
}
// export const DelegationAtom = atom<DelegationSelect | null>(null);

export const InitDelegationAtom = atom<string[]>([]);

export const DelegationAtom = atom(
  (get) => get(InitDelegationAtom),
  (
    get,
    set,
    newDelegation: {
      type: 'update' | 'reset' | 'init';
      value?: string;
      values?: string[];
    }
  ) =>
    // eslint-disable-next-line consistent-return
    {
      let clonedDelegation: string[] = [];
      if (newDelegation?.type === 'update') {
        if (newDelegation !== null && newDelegation.value !== undefined) {
          if (get(InitDelegationAtom).includes(newDelegation.value)) {
            clonedDelegation = get(InitDelegationAtom).filter(
              (elem) => elem !== newDelegation.value
            );
          } else
            clonedDelegation = [
              ...get(InitDelegationAtom),
              newDelegation.value,
            ];
        }
      } else if (newDelegation?.type === 'reset') clonedDelegation = [];
      else if (newDelegation?.type === 'init' && newDelegation.values)
        clonedDelegation = newDelegation.values;

      // @ts-nocheck
      set(InitDelegationAtom, clonedDelegation);
    }
);

export const DelegationListAtom = atom<DelegationSelect[]>([]);

export const InitGovernorateAtom = atom<GovernorateSelect | null>(null);
export const GovernorateAtom = atom(
  (get) => get(InitGovernorateAtom),
  (_, set, newGovernorate: GovernorateSelect | null) => {
    if (newGovernorate !== null) {
      set(InitGovernorateAtom, newGovernorate);
      const newDelegationList = getDelegationsOfGovernorate(newGovernorate.id);
      set(DelegationListAtom, newDelegationList);
    } else {
      set(InitGovernorateAtom, null);
      set(DelegationListAtom, []);
    }
    set(DelegationAtom, { type: 'reset' });
  }
);

export interface RangeParamsAction {
  type: 'setMin' | 'setMax' | 'empty';
  data?: SelectedParamsRangeValue;
}
export const RangeParamsAtom = atom(
  (get) => get(selectedParamsRangeAtom),
  (_, set, action: RangeParamsAction) => {
    if (action.type === 'empty') {
      set(selectedParamsRangeAtom, {});
    } else if (action.data !== undefined && action.data.name !== undefined) {
      if (action.type === 'setMin') {
        set(selectedParamsRangeAtom, (prev: SelectedParamsRange) => {
          if (
            action.data!.selectedMin === '' &&
            (prev[action.data!.name]?.selectedMax === undefined ||
              prev[action.data!.name]?.selectedMax === '')
          ) {
            const prevCopy = { ...prev };
            delete prevCopy[action.data!.name];
            return prevCopy;
          }
          return {
            ...prev,
            [action.data!.name]: {
              ...(prev[action.data!.name] as {}),
              ...action.data,
            },
          } as SelectedParamsRange;
        });
      } else {
        // if (action.type === 'setMax') {
        set(selectedParamsRangeAtom, (prev: SelectedParamsRange) => {
          if (
            action.data!.selectedMax === '' &&
            (prev[action.data!.name]?.selectedMin === undefined ||
              prev[action.data!.name]?.selectedMin === '')
          ) {
            const prevCopy = { ...prev };
            delete prevCopy[action.data!.name];
            return prevCopy;
          }
          return {
            ...prev,
            [action.data!.name]: {
              ...(prev[action.data!.name] as {}),
              ...action.data,
            },
          } as SelectedParamsRange;
        });
      }
    }
  }
);

export interface OptionParam {
  name: string;
  value: string;
}
export const OptionsParamsAtom = atom(
  (get) => get(selectedParamsMultiSelectAtom),
  (get, set, newOptionParam: OptionParam) => {
    if (newOptionParam.value !== '') {
      set(selectedParamsMultiSelectAtom, (prev: SelectedParamsOptions) => ({
        ...prev,
        [newOptionParam.name]: newOptionParam.value,
      }));
    } else {
      set(selectedParamsMultiSelectAtom, (prev: SelectedParamsOptions) => {
        const prevCopy = { ...prev };
        delete prevCopy[newOptionParam.name];
        return prevCopy;
      });
    }

    if (newOptionParam.name === 'Marque') {
      if (newOptionParam.value !== '')
        set(ParamsListAtom, (params) =>
          params.map((param) => {
            if (param.dependant === newOptionParam.name) {
              const carModels = param.fixedPossiblesValues
                ?.filter((possibleValue) =>
                  possibleValue.startsWith(newOptionParam.value)
                )
                .map((modelWithPrefix) =>
                  modelWithPrefix.substring(newOptionParam.value.length + 1)
                );

              return {
                ...param,
                possiblesValues: carModels,
                disabled: false,
              };
            }
            return param;
          })
        );
      else {
        set(ParamsListAtom, (params) =>
          params.map((param) => {
            if (param.dependant === newOptionParam.name) {
              return {
                ...param,
                possiblesValues: [],
                disabled: true,
              };
            }
            return param;
          })
        );
        set(selectedParamsMultiSelectAtom, (prev) => {
          const prevCopy = { ...prev };
          delete prevCopy['Modèle'];
          return prevCopy;
        });
        // remove this on refactor
        process.nextTick(() => {
          set(FilterAtom, (prev) => ({
            ...prev,
            adParams: get(OptionsParamsAtom),
          }));
        });
      }
    }

    // removed in favour to selction one item
    // if (get(selectedParamsMultiSelectAtom)[action.name]) {
    //   const foundParam = get(selectedParamsMultiSelectAtom)[action.name]?.find(
    //     (option) => option === action.name
    //   );

    //   if (foundParam) {
    //     set(
    //       selectedParamsMultiSelectAtom,
    //       (prev: SelectedParamsOptions): SelectedParamsOptions =>
    //         ({
    //           ...prev,
    //           [action.name]: prev[action.name]?.filter(
    //             (option) => action.value !== option
    //           ),
    //         } as SelectedParamsOptions)
    //     );
    //   } else {
    //     set(selectedParamsMultiSelectAtom, (prev: SelectedParamsOptions) => ({
    //       ...prev,
    //       [action.name]: isArray(action.value)
    //         ? action.value
    //         : [...(prev[action.name] as []), action.value],
    //     }));
    //   }
    // } else {
    //   set(selectedParamsMultiSelectAtom, (prev: SelectedParamsOptions) => ({
    //     ...prev,
    //     [action.name]: isArray(action.value) ? action.value : [action.value],
    //   }));
    // }
  }
);

export interface CategoryAction {
  type: 'set' | 'empty';
  selectedCategory?: SelectedCategory;
}

export const SubCategoryAtom = atom(
  (get) => get(selectedSubcategoryAtom),
  async (get, set, action: CategoryAction) => {
    if (action.type === 'set' && action.selectedCategory !== undefined) {
      const prevSelectSubCategoryId = get(selectedSubcategoryAtom).id;
      if (
        action.selectedCategory.id &&
        action.selectedCategory.id !== prevSelectSubCategoryId
      ) {
        set(selectedParamsMultiSelectAtom, {});
        set(RangeParamsAtom, { type: 'empty' });

        const categoryByIDRequest = new CategoryByIDRequest();
        categoryByIDRequest.setId(action.selectedCategory.id);
        const subCategoryParams = await getCategory(categoryByIDRequest);
        if (subCategoryParams) {
          set(
            ParamsListAtom,
            mapCategoryResponseToIParams(subCategoryParams) as IParams[]
          );

          // workaround code until refactor. used to set car models select
          // options when navigating from query params
          const carMake = get(OptionsParamsAtom).Marque;
          if (carMake !== undefined)
            process.nextTick(() => {
              set(OptionsParamsAtom, { name: 'Marque', value: carMake });
            });
        }
      }

      set(selectedSubcategoryAtom, {
        id: action.selectedCategory.id,
        name: action.selectedCategory.name,
      });
    } else {
      // if (type === 'empty')
      set(selectedSubcategoryAtom, {});
      // set(selectedParamsMultiSelectAtom, {});
      set(RangeParamsAtom, { type: 'empty' });
      set(ParamsListAtom, []);
    }
  }
);

export type SubCategoryList = Array<{
  value: string;
  label: string;
}>;
export const SubCategoryListAtom = atom<SubCategoryList>([]);

export const CategoryAtom = atom(
  (get) => get(SelectedCategoryAtom),
  (get, set, action: CategoryAction) => {
    if (action.type === 'set' && action.selectedCategory !== undefined) {
      const prevCategoryId = get(SelectedCategoryAtom).id;
      if (action.selectedCategory.id !== prevCategoryId) {
        set(selectedSubcategoryAtom, {});
        set(selectedParamsMultiSelectAtom, {});
        set(RangeParamsAtom, { type: 'empty' });
        set(ParamsListAtom, []);

        const newSubCatgoryList = categories
          .filter(
            (cat) => cat.parentcategoryxid === action.selectedCategory!.id
          )
          .map(({ id, name }) => ({
            value: id,
            label: name,
          }));
        set(SelectedCategoryAtom, {
          id: action.selectedCategory.id,
          name: action.selectedCategory.name,
        });
        set(SubCategoryListAtom, newSubCatgoryList);
      }
    } else {
      // if (action.type === 'empty')
      set(selectedSubcategoryAtom, {});
      set(selectedParamsMultiSelectAtom, {});
      set(RangeParamsAtom, { type: 'empty' });
      set(ParamsListAtom, []);
      set(SelectedCategoryAtom, {});
      set(SubCategoryListAtom, []);
    }
  }
);

// used to search for profile / shop module

export interface PrivateSearchFilterType {
  status: number;
  category: string;
  subcategory: string;
  delegation: string;
  governorate: string;
}

export const PrivateFilterAtom = atom<PrivateSearchFilterType>({
  category: '',
  subcategory: '',
  delegation: '',
  governorate: '',
  status: SHOW_ALL_ADS,
});

export interface PrivateSearchListStore {
  list: IListingItem[];
  totalListCount: number;
  limit: number;
  offset: number;
  sort: string;
  query: string;
  userid: string;
  isPublic: boolean;
  status: number;
}

const initPrivateSearchListStore = atom<PrivateSearchListStore>({
  list: [],
  totalListCount: 0,
  limit: LIMIT,
  offset: 0,
  sort: '',
  query: '',
  userid: '',
  status: SHOW_ALL_ADS,
  isPublic: false,
});

export interface PrivateSearchListAction {
  type: 'init' | 'inc' | 'filter';
  newList: IListingItem[];
  sort?: string;
  totalListCount?: number;
  query?: string;
  userid?: string;
  isPublic?: boolean;
  status?: number;
}

export const AdsListAtom = atom(
  (get) => get(initPrivateSearchListStore),

  (_, set, action: PrivateSearchListAction) => {
    switch (action.type) {
      case 'init':
      case 'filter':
        set(initPrivateSearchListStore, (prev: PrivateSearchListStore) => {
          const newListAds =
            action.newList !== undefined ? action.newList : prev.list;

          const newTotalCount = action.totalListCount
            ? action.totalListCount
            : prev.totalListCount;

          const res: PrivateSearchListStore = {
            ...prev,
            limit: prev.limit,
            offset: 0,
            list: newListAds,
            totalListCount: newTotalCount,
            query: prev.query,
            sort: prev.sort,
            userid: action.userid,
            status: action.status,
          } as PrivateSearchListStore;

          return res;
        });

        break;

      case 'inc':
        set(initPrivateSearchListStore, (prev: PrivateSearchListStore) => {
          const res: PrivateSearchListStore = {
            ...prev,

            offset: prev.offset + prev.limit,
            list: [...prev.list, ...action.newList],
          };
          return res as PrivateSearchListStore;
        });
        break;

      default:
        break;
    }
  }
);

export const InitProductTypeAtom = atom<number[]>([]);

export const ProductTypeAtom = atom(
  (get) => get(InitProductTypeAtom),
  (
    get,
    set,
    newProductType: {
      type: 'update' | 'reset' | 'init';
      value?: number;
      values?: number[];
    }
  ) =>
    // eslint-disable-next-line consistent-return
    {
      let clonedProductType: number[] = [];
      if (newProductType?.type === 'update') {
        if (newProductType !== null && newProductType.value !== undefined) {
          if (get(InitProductTypeAtom).includes(newProductType.value)) {
            clonedProductType = get(InitProductTypeAtom).filter(
              (elem) => elem !== newProductType.value
            );
          } else
            clonedProductType = [
              ...get(InitProductTypeAtom),
              newProductType.value,
            ];
        }
      } else if (newProductType.type === 'reset') clonedProductType = [];
      else if (newProductType.type === 'init' && newProductType.values)
        clonedProductType = newProductType.values;

      // @ts-nocheck
      set(InitProductTypeAtom, clonedProductType);
    }
);

import React, { useEffect, useRef, useState, useCallback, FC } from 'react';

import { useRouter } from 'next/router';
import { createPortal } from 'react-dom';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useBoolean } from 'usehooks-ts';

interface XCarouselProps {
  children: JSX.Element | JSX.Element[];
  portalEl?: Element | null;
  setIsCompact?: React.Dispatch<React.SetStateAction<boolean>>;
  isCompact?: boolean;
  setScrollRef?: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement>>
  >;
  onStopScroll?: (progress: number) => void;
  topOffset?: number; // Conditio to recalculate Buttons top position when something specific has updated
}

const XCarousel: FC<XCarouselProps> = ({
  children,
  portalEl,
  setScrollRef,
  onStopScroll,
  topOffset,
}) => {
  const {
    value: hasHover,
    setTrue: setHasHoverTrue,
    setFalse: setHasHoverFalse,
  } = useBoolean(false);

  const [stepDirection, setStepDirection] = useState<1 | -1>(1);
  const teleportedRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number>(0);
  const prevCheckpoint = useRef<number>(-1);
  const lockedRef = useRef<boolean>(true);
  const elapsedRef = useRef<number>(0);
  const [progress, setProgress] = useState<number>(0);
  if (portalEl && teleportedRef.current) {
    createPortal(teleportedRef.current, portalEl);
  }
  const { asPath } = useRouter();

  useEffect(() => {
    let portal: DOMRect = new DOMRect();
    if (scrollRef.current) {
      portal = scrollRef.current.getBoundingClientRect();
    }
    if (portalEl) {
      portal = portalEl.getBoundingClientRect();
    }

    if (targetRef.current && portalEl && teleportedRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();

      teleportedRef.current.style.top = `${
        topOffset
          ? Number(topOffset)
          : 0 +
            Math.round(targetRect.y - portal.top + (targetRect.height / 4) * 2)
      }px`;
    }
  }, [targetRef, portalEl, asPath, topOffset]);

  const handleScroll = () => {
    if (scrollRef.current) {
      setProgress(
        Math.round(
          (scrollRef.current.scrollLeft /
            (scrollRef.current.scrollWidth -
              scrollRef.current.getBoundingClientRect().width)) *
            100
        )
      );
    }
  };

  const scrollTimeout = useRef(-1);
  useEffect(() => {
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = window.setTimeout(
      () => onStopScroll?.(progress),
      600
    );
  }, [onStopScroll, progress]);

  useEffect(() => {
    if (scrollRef && setScrollRef) {
      setScrollRef(scrollRef);
    }
  }, [scrollRef, setScrollRef]);

  const handleMouseClick = () => {
    setHasHoverFalse();
    if (scrollRef.current && targetRef.current) {
      scrollRef.current.scrollLeft +=
        (targetRef.current.getBoundingClientRect().width / 2) * stepDirection;
    }
  };

  const handleMouseEnter = (direction: -1 | 1) => {
    setStepDirection(direction);
    setHasHoverTrue();
  };

  const handleMouseLeave = () => setHasHoverFalse();

  const STEP_DURATION = 1200;
  const STEP_SIZE = 210;
  const animate = useCallback(() => {
    const timestamp = Date.now();

    if (prevCheckpoint.current === -1) {
      prevCheckpoint.current = timestamp;
      lockedRef.current = false;
    } else {
      elapsedRef.current = timestamp - prevCheckpoint.current;
    }

    if (elapsedRef.current > STEP_DURATION) {
      lockedRef.current = false;
    }

    if (lockedRef.current === false && scrollRef.current) {
      lockedRef.current = true;
      scrollRef.current.scrollLeft += STEP_SIZE * stepDirection;
    }

    if (elapsedRef.current > STEP_DURATION) {
      prevCheckpoint.current = timestamp;
      elapsedRef.current = 0;
    }

    requestRef.current = requestAnimationFrame(animate);
  }, [requestRef, stepDirection]);

  useEffect(() => {
    if (hasHover) {
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [hasHover, requestRef, animate]);

  return (
    <div ref={targetRef}>
      <div
        className="md:flex lg:flex my-auto hidden absolute top-0 right-2 translate-x-1/2  z-20 xl:flex flex-col gap-2"
        ref={teleportedRef}
      >
        <button
          type="button"
          onClick={handleMouseClick}
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={handleMouseLeave}
          className="bg-primary text-white p-2.5  rounded-full shadow-lg shadow-primary/30 hover:shadow-primary/50 transition-shadow"
        >
          <HiChevronRight size={26} />
        </button>
        <button
          type="button"
          onClick={handleMouseClick}
          onMouseEnter={() => handleMouseEnter(-1)}
          onMouseLeave={handleMouseLeave}
          className=" shadow-md shadow-neutral-300  bg-white transition-colors text-neutral-500 p-1 mx-auto z-[1] rounded-full"
        >
          <HiChevronLeft size={26} />
        </button>
      </div>
      <div className="">
        <div
          className="h-1 w-full bg-primary origin-left transition-transform ease-linear"
          style={{ transform: `scaleX(${progress}%)` }}
        ></div>
        <div
          className="mx-0 h-full w-full scrollbar-none overflow-x-auto scroll-ml-px pl-px scroll-smooth snap-x snap-mandatory"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default XCarousel;

import useSearchHistory from '../../hooks/useSearchHistory';

interface SearchHistoryStripProps {
  onSelect: (term: string) => void;
}

const SearchHistoryStrip: React.FC<SearchHistoryStripProps> = ({
  onSelect,
}) => {
  const { searchHistory: queryHistory } = useSearchHistory();
  const handleSelection = (term: string) => {
    onSelect(term);
  };
  return (
    <div className="w-fit py-1 flex items-center pointer-events-auto">
      <span className="hidden lg:block text-2xs font-semibold text-neutral-400/80 whitespace-nowrap">
        Votre récente recheche:
      </span>
      {/* Horizontal scroll strip */}
      <div className="flex gap-x-1 whitespace-nowrap overflow-x-auto scrollbar-none">
        {queryHistory.length > 0 ? (
          queryHistory.splice(0, 1).map((term, index) => (
            <button
              type="button"
              onClick={() => handleSelection(term)}
              key={index}
              className="badge bg-white text-neutral-400 !text-xs !font-light active:bg-neutral-200 active:text-neutral-600"
            >
              {term}
            </button>
          ))
        ) : (
          <p className="text-2xs font-bold text-neutral-300 whitespace-nowrap rounded-md bg-white pl-1">
            Pas de recheches récentes
          </p>
        )}
      </div>
    </div>
  );
};

export default SearchHistoryStrip;

import { useEffectOnce, useLocalStorage } from 'usehooks-ts';

import { APP_CONFIG } from '@/app-config';

const LOCAL_STORAGE_KEY = 'user.classifieds_history';

const updateHistory = (updateFn: Function, h: string[], id: string) => {
  const history = h;
  history.unshift(id);
  if (history.lastIndexOf(id) !== history.indexOf(id)) {
    history.splice(history.lastIndexOf(id), 1);
  }
  if (history.length > APP_CONFIG.VISITED_HISTORY_MAX_LENGTH) {
    history.pop();
  }
  updateFn(history);
};

export const usePrependOneToLocalClassifiedsHistory = (
  classifiedId: string
) => {
  const [history, updateFn] = useLocalStorage<string[]>(LOCAL_STORAGE_KEY, []);
  useEffectOnce(() => {
    updateHistory(updateFn, [...history], classifiedId);
  });
};

export const useExtendedSearch = () => {
  const [localHistory, updateLocalHistory] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY,
    []
  );

  const resetLocalClassifiedsHistory = () => {
    updateLocalHistory([]);
  };

  return {
    history: localHistory,
    refineLocalClassifiedsHistory: updateLocalHistory,
    resetLocalClassifiedsHistory,
  };
};

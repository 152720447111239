import GoogleAd from '../GoogleAd';

const MobileStickyBanner = () => (
  <div
    aria-hidden
    tabIndex={-1}
    className="absolute top-0  mx-auto  w-[360px] h-[100px]"
  >
    <GoogleAd
      // id='WjrHGivrzGd_0B7mJN7eS'
      ad={{
        slot: '/118262226/stickyweb',
        mapping: {
          0: [360, 100],
        },
        sizes: [
          [350, 45],
          [360, 50],
          [360, 100],
          [390, 50],
          [390, 100],
          'fluid',
        ],
      }}
      className={'w-full h-full overflow-hidden'}
    />
  </div>
);

export default MobileStickyBanner;

import { formatPrice } from '@/modules/core/utils/utils';
import Text from '@/tayara-kit/Text';

export enum PriceType {
  Gold,
  Normal,
  Spotlight,
  Neuf,
}

interface PriceProps extends React.HTMLAttributes<HTMLDataElement> {
  price: number;
  type?: PriceType;
  className?: string;
  unitComponent?: JSX.Element;
}

const Price = ({ price, type, className, unitComponent }: PriceProps) => (
  <data
    value={price}
    className={`font-bold font-arabic ${
      // eslint-disable-next-line no-nested-ternary
      type === PriceType.Gold
        ? 'text-yellow-600'
        : type === PriceType.Spotlight
        ? 'text-white font-bold'
        : type === PriceType.Neuf
        ? 'text-neuf-200'
        : 'text-red-600 '
    } ${className}`}
  >
    {price > 1
      ? formatPrice(price)
          ?.split(',')
          .map((part, index) => (
            <span key={index} className="mr-1">
              {part}
            </span>
          ))
      : null}
    {
      price > 1
        ? unitComponent || <Text className="text-xs font-medium" text="DT" />
        : null
      // <Text className="text-sm font-medium text-gray-500" text="currency" />
    }
  </data>
);

export default Price;

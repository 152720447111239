import { useEffect, useState } from 'react';

import { useBoolean } from 'usehooks-ts';

interface FocusState<T> {
  hasFocus: boolean;
  ref: T | null;
}
function useFocus<T extends HTMLElement = HTMLInputElement>(
  initState?: boolean
): [(node: T | null) => void, FocusState<T>] {
  const [ref, setRef] = useState<T | null>(null);
  const {
    value: hasFocus,
    setTrue: setHasFocusTrue,
    setFalse: setHasFocusFalse,
  } = useBoolean(initState || false);

  useEffect(() => {
    const node = ref; // DOM Ref

    node?.addEventListener('focus', setHasFocusTrue);
    node?.addEventListener('blur', setHasFocusFalse);

    return () => {
      node?.removeEventListener('focus', setHasFocusTrue);
      node?.removeEventListener('blur', setHasFocusFalse);
    };
  }, [ref, setHasFocusFalse, setHasFocusTrue]);

  return [setRef, { hasFocus, ref }];
}

export default useFocus;

import React, { useRef } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { useAtom } from 'jotai';
import { flatMap } from 'lodash-es';
import Link from 'next/link';
import { IconType } from 'react-icons';
import { BiMessageAlt } from 'react-icons/bi';
import { CgMenu } from 'react-icons/cg';
import { FaUserCircle } from 'react-icons/fa';
import {
  HiLogout,
  HiOutlineCollection,
  HiOutlineShieldCheck,
  HiOutlineUserCircle,
} from 'react-icons/hi';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { RiDashboard2Line } from 'react-icons/ri';
import { useHover } from 'usehooks-ts';

import { UserDataAtom } from '@/modules/core/store/grpc-free-store';
import { UserType } from '@/modules/profile/types';

import { TkTokenHistory } from './icons';

interface ProfileMenu {
  label: string;
  icon: IconType;
  action?: () => void;
  link?: string;
}

interface ProfileAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  logout?: () => void;
}
/**
 * Profile Avatar component to display profile picture if it exists or fallback to other avatar options
 * on error
 * @returns JSX.Element button
 */
const ProfileAvatar = ({ logout, ...props }: ProfileAvatarProps) => {
  // const { userData } = useProfileHook();
  const userData = useAtom(UserDataAtom);
  const targetRef = useRef<HTMLDivElement>(null);
  const hasHover = useHover(targetRef);
  const { avatar } = userData[0];

  const MENU: ProfileMenu[] = flatMap([
    userData[0].userType === UserType.PRO
      ? {
          label: 'Tableau de bord',
          icon: RiDashboard2Line,
          link: '/profile?t=dashboard',
        }
      : [],
    {
      label: 'Mes annonces',
      icon: HiOutlineCollection,
      link: '/profile?t=myListings',
    },
    {
      label: 'Mes alertes',
      icon: MdOutlineNotificationsActive,
      link: '/profile?t=myAlerts',
    },
    {
      label: 'Mes Messages',
      icon: BiMessageAlt,
      link: '/chat',
    },
    {
      label: 'Mes transactions',
      icon: TkTokenHistory,
      link: '/profile?t=transactions',
    },
    {
      label: 'Mes informations',
      icon: HiOutlineUserCircle,
      link: '/profile?t=details',
    },
    {
      label: 'Sécurité',
      icon: HiOutlineShieldCheck,
      link: '/profile?t=security',
    },

    // This empty object acts as a divider
    { label: '', icon: {} as IconType },
    {
      label: 'Se déconnecter',
      icon: HiLogout,
      action: logout,
    },
  ]);

  return (
    <div className="" ref={targetRef} {...props}>
      <Popover className="relative">
        <Popover.Button
          as="div"
          className={`flex items-center justify-center transition-transform active:scale-90
            gap-x-2 py-[5px] px-4 cursor-pointer  lg:pr-3 rounded-xl border-[1px] border-neutral-300 hover:bg-neutral-100 hover:border-neutral-200
            `}
        >
          <Link href="/profile" passHref>
            <div className="avatar items-center relative align-top">
              <CgMenu size={22} className="mx-2 text-neutral-500" />
              <div className={`mask mask-squircle w-[27px]`}>
                {avatar ? (
                  <img
                    className="w-full"
                    src={
                      avatar.includes('http')
                        ? `https://www.tayara.tn/mediaGateway/resize-image?img=${avatar.substring(
                            avatar.lastIndexOf('/') - 2
                          )}&w=100`
                        : `https://www.tayara.tn/mediaGateway/resize-image?img=${avatar}&w=100`
                    }
                    alt="tayara publisher profile picture"
                    loading="lazy"
                  />
                ) : (
                  <div className="bg-neutral-700 text-neutral-100 w-full h-full flex">
                    <FaUserCircle size={22} className="m-auto" />
                  </div>
                )}
              </div>
            </div>
          </Link>

          <div className="text-xs font-extrabold text-neutral-500 truncate max-w-[50px] hidden md:hidden xl:block">
            {userData[0].fullname}
          </div>
        </Popover.Button>

        <Transition
          as="div"
          show={hasHover}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel static className="absolute right-2 hidden lg:block">
            <ul className="menu rounded-box mt-2 -mr-2 w-52 bg-white p-2 shadow backdrop-brightness-150">
              <span className="mt-1 mb-2 ml-2 font-bold text-xs text-primary">
                Votre profil
              </span>
              {MENU.map(({ icon: Icon, label, action, link }, index) => (
                <li key={index}>
                  {action && (
                    <button
                      onClick={action}
                      className="border-none bg-white text-neutral-700 hover:bg-neutral-100 hover:text-neutral-800 text-xs"
                    >
                      <Icon size={18} />
                      {label}
                    </button>
                  )}
                  {link && (
                    <Link
                      href={link}
                      passHref
                      className="border-none bg-white text-neutral-700 hover:bg-neutral-100 hover:text-neutral-800 text-xs"
                    >
                      <Icon size={18} />
                      {label}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default ProfileAvatar;

import Link from 'next/link';
import { BreadcrumbJsonLd } from 'next-seo';

import { APP_CONFIG } from '@/app-config';
import useBreadcrumbs from '@/modules/core/hooks/useBreadcrumbs';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div>
      <nav className="text-sm breadcrumbs" aria-label="breadcrumbs">
        <ul>
          {breadcrumbs.map(({ link, label, icon: Icon }) => (
            <li key={link}>
              <Link
                href={{ pathname: link }}
                passHref
                className="w-full border-none hover:text-gray-600 text-gray-500 text-2xs hover:!no-underline gap-x-1 flex items-center"
              >
                <Icon size={14} />
                <span className="truncate max-w-[80px] md:max-w-[150px]">
                  {label}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <BreadcrumbJsonLd
        itemListElements={breadcrumbs.map((e, i) => ({
          position: i,
          name: e?.label,
          item: `${APP_CONFIG.basePath}/${e.link}`,
        }))}
      />
    </div>
  );
};

export default Breadcrumbs;

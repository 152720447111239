import { FC, MouseEventHandler } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import Price from '@/tayara-kit/Price';

import { IListingItem } from '../../types';
import { genItemLink } from '../../utils/utils';

enum Size {
  light,
  default,
}
interface AvailableSizes {
  [key: string]: string | number;
}
const AVAILABLE_SIZES: AvailableSizes = {
  default: 136,
  lg: 215,
};
// Tailwind clases should not be dynamically generated, so let's repeat ourselves.
const AVAILABLE_SIZE_CLASSES: AvailableSizes = {
  default: 'w-[136px]',
  lg: 'w-[215px]',
};
interface CompactClassifiedCardProps {
  classifiedData: IListingItem;
  size?: Size;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const CompactClassifiedCard: FC<CompactClassifiedCardProps> = ({
  classifiedData,
  size = 'default',
  onClick,
}) => (
  <Link href={genItemLink(classifiedData)} passHref onClick={onClick}>
    <div
      className={`flex flex-col cursor-pointer hover:bg-neutral-100 rounded-xl p-2 ${AVAILABLE_SIZE_CLASSES[size]} `}
    >
      {/* Classfied image */}
      <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden">
        <Image
          src={
            classifiedData.images[0]?.includes('http')
              ? `https://www.tayara.tn/mediaGateway/resize-image?img=${classifiedData.images[0].substring(
                  classifiedData.images[0].lastIndexOf('/') - 2
                )}&w=300`
              : `https://www.tayara.tn/mediaGateway/resize-image?img=${classifiedData.images[0]}&w=300`
          }
          unoptimized
          loading="lazy"
          {...(classifiedData.imgLoad
            ? {
                placeholder: 'blur',
                blurDataURL: `data:image/webp;base64,${classifiedData.imgLoad}`,
              }
            : {})}
          // To do check why width is set to 640 when using loader
          className={`bg-neutral-300 w-full h-full object-cover`}
          width={parseInt(`${AVAILABLE_SIZES[size] || 136}`, 10)}
          height={parseInt(`${AVAILABLE_SIZES[size] || 136}`, 10)}
          // TODO Make this an actual alternative title
          alt={`${classifiedData.title}`}
        />
      </div>

      {/* Classfied body */}
      <div className="mt-1">
        {/* Classfifed price */}
        <div className="min-h-[21px]">
          {classifiedData.price > 1 ? (
            <Price
              className="text-sm font-bold text-neutral-700 "
              price={classifiedData.price}
            />
          ) : (
            <p className="text-neutral-400/80 text-xs font-bold">Sans prix</p>
          )}
        </div>

        {/* Classified title */}
        <p className="text-2xs text-neutral-600 leading-tight line-clamp-2 min-h-[28px]">
          {classifiedData.title}
        </p>
      </div>
    </div>
  </Link>
);

export default CompactClassifiedCard;

// Classified page view events (page annonce)
export enum ClassifiedPageEvents {
  VIEW_PAGE = 'CLASSIFIED_PAGE--VIEW_PAGE',
  CLICK_PHONE = 'CLASSIFIED_PAGE--CLICK_PHONE',
  COPY_PHONE = 'CLASSIFIED_PAGE--COPY_PHONE',
  CLICK_CHAT = 'CLASSIFIED_PAGE--CLICK_CHAT',
  SEND_CHAT = 'CLASSIFIED_PAGE--SEND_CHAT',
  CLICK_AVATAR_PUBLISHER_CARD = 'CLASSIFIED_PAGE->PUBLISHER_CARD--CLICK_AVATAR',
  CLICK_AVATAR_MORE_FROM_USER = 'CLASSIFIED_PAGE->MORE_FROM_USER--CLICK_AVATAR',
}

// Golden classified events
export enum GoldEvents {
  CLICK_CLASSIFIED = 'GOLD--CLICK_CLASSIFIED',
  CLICK_AVATAR = 'GOLD--CLICK_AVATAR',
  CLICK_NAME = 'GOLD--CLICK_NAME',
  CLICK_PHONE = 'GOLD--CLICK_PHONE',
}

// Classified carousel events
export enum CarouselEvents {
  SCROLL_CAROUSEL = 'CAROUSEL--SCROLL_CAROUSEL',
  CLICK_CLASSIFIED = 'CAROUSEL--CLICK_CLASSIFIED',
  CLICK_SHOP_OF_MONTH = 'CAROUSEL--CLICK_SHOP_OF_MONTH',
  CLICK_IQOS_SHOP = 'CAROUSEL--CLICK_IQOS_SHOP',
  CLICK_SHOW_ALL = 'CAROUSEL--CLICK_SHOW_ALL',
  CLICK_POSTER = 'CAROUSE--CLICK_POSTER',
}

// Premium classifieds events
// enum PremiumCarouselEvents {
//   SCROLL_CAROUSEL = 'GOLD--CLICK_CLASSIFIED',
//   CLICK_AVATAR = 'GOLD--CLICK_AVATAR',
//   CLICK_NAME = 'GOLD--CLICK_NAME',
//   CLICK_PHONE = 'GOLD--CLICK_PHONE',
// }

type RudderEvent = GoldEvents | ClassifiedPageEvents | CarouselEvents;

export default RudderEvent;

import { useEffect, useState } from 'react';
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/naming-convention */

import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import JSCookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import {
  ChangePasswordService,
  GetUser,
  login,
  refresh,
  SendOtp,
  VerifyOtpService,
} from '@/api/fetch/auth-service';
import { GetUserById } from '@/api/fetch/marketplace-api';
import {
  ChangePasswordRequestGRPC,
  GetUserRequestGrpc,
  LoginRequest,
  RefreshTokenRequest,
  SendOtpRequest,
  VerifyOtpRequestGRPC,
} from '@/api/gen-protos/auth_pb';
import { UserByIDRequest } from '@/api/gen-protos/users_pb';
import { Value } from '@/lib/constants';
import {
  AuthErrorsAtom,
  ForgetPasswordIdentifiantAtom,
  pageLoading,
  PageLoadingAction,
} from '@/lib/store';
import { validatePhoneNumber } from '@/lib/utils';
import {
  AuthTokenAtom,
  UserDataAtom,
} from '@/modules/core/store/grpc-free-store';

import { User } from '../../../models/user';

type FirstLoggedObject = {
  kcid: string;
  loggedinaftercutoff: boolean;
  phonenumber: string;
  username: string;
  keycloakusername: string;
};

const useLoginHook = () => {
  const [, setAuthToken] = useAtom(AuthTokenAtom);

  const [refresh_expires_in, setRefresh_expires_in] = useLocalStorage<
    Value<number>
  >('refresh_expires_in', null);
  const router = useRouter();
  const [refresh_token, setRefresh_token] = useLocalStorage<Value<string>>(
    'refresh_token',
    null
  );
  const [, setExpires_in] = useLocalStorage<Value<number>>('expires_in', null);

  // const [loading, setloading] = useState(false);
  const [identifiant, setIdentifiant] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [newRePassword, setnewRePassword] = useState('');
  const [loggedUserData, setUserFirstTimeData] = useState<FirstLoggedObject>();
  const [, setIdentifiantForForgetPassword] = useAtom(
    ForgetPasswordIdentifiantAtom
  );
  const [, setUserData] = useAtom(UserDataAtom);

  const resetForm = () => {
    setIdentifiant('');
    setVerificationCode('');
    setnewRePassword('');
    setnewPassword('');
    setVerificationCode('');
    setUserFirstTimeData(undefined);
  };

  const [error, setError] = useAtom(AuthErrorsAtom);

  const [, setIsLoading] = useAtom(pageLoading);

  useEffect(() => {
    setError({});
  }, [setError]);

  const udpdateError = (errorKey: string, message: string) => {
    setError({
      ...error,
      [errorKey]: message,
    });
  };

  const resetError = () => {
    setError({});
  };

  const refrechToken = () => {
    if (Date.now() / 1000 + 120 >= +(refresh_expires_in || 0)) {
      const refreshTokenRequest = new RefreshTokenRequest();
      refreshTokenRequest.setRefreshToken(refresh_token || '');
      refresh(refreshTokenRequest).then((refreshResult) => {
        setRefresh_token(refreshResult.getRefreshToken());
        setExpires_in(refreshResult.getExpiresIn());
        setAuthToken(refreshResult.getToken());
        setRefresh_expires_in(refreshResult.getRefreshExpiresIn());
      });
    }
  };

  const updatePassword = async () => {
    setIsLoading(PageLoadingAction.Start);
    if (
      loggedUserData?.phonenumber &&
      newPassword &&
      newRePassword &&
      newPassword === newRePassword
    ) {
      const changePasswordRequest = new ChangePasswordRequestGRPC();
      changePasswordRequest.setPassword(newPassword);
      changePasswordRequest.setUserid(loggedUserData.kcid);
      const res = await ChangePasswordService(changePasswordRequest).catch(
        (err) => {
          udpdateError('new-password', err);
        }
      );
      if (res) {
        if (!loggedUserData.loggedinaftercutoff) resetForm();
        resetError();
        router.push({
          pathname: (router.query.redirect as string) || '/login',
        });
      }
    } else {
      udpdateError('new-password', 'vérifier les mots de passe');
    }
    setIsLoading(PageLoadingAction.Stop);
  };

  const verifyLastUserConnexion = async () => {
    if (!identifiant) {
      setError({
        email: "Nom d'utilisateur est nécessaire",
      });
      return;
    }

    setIsLoading(PageLoadingAction.Start);

    let res;
    if (validatePhoneNumber(identifiant)) {
      const req = new GetUserRequestGrpc();
      req.setUsername(identifiant);
      res = await GetUser(req).catch(() => {
        setError({
          email: "les informations d'identification invalides",
        });
      });
    } else {
      const req = new GetUserRequestGrpc();
      req.setUsername(identifiant);
      res = await GetUser(req).catch(() => {
        setError({
          email: "les informations d'identification invalides",
        });
      });
    }

    if (res) {
      setUserFirstTimeData(res.toObject());
      udpdateError('password', '');
      udpdateError('access', '');

      router.push({
        query: {
          ...router.query,
          screen: res.getLoggedinaftercutoff() ? 'password' : 'access',
        },
      });
    }
    router.push({
      query: {
        ...router.query,
        screen: 'password',
      },
    });
    setIsLoading(PageLoadingAction.Stop);
  };

  const sendOtpSms = async () => {
    setIsLoading(PageLoadingAction.Start);

    if (loggedUserData && loggedUserData.phonenumber) {
      const sendOtpRequest = new SendOtpRequest();
      sendOtpRequest.setPhonenumber(loggedUserData.phonenumber);
      await SendOtp(sendOtpRequest).then((res) => {
        if (res.getMessage() === 'Otp Send Successfully') {
          router.push({
            query: {
              ...router.query,
              screen: 'sms',
            },
          });
        }
      });
    }

    setIsLoading(PageLoadingAction.Stop);
  };

  const VerifyOtpSms = async () => {
    setIsLoading(PageLoadingAction.Start);

    udpdateError('sms', '');
    if (!loggedUserData?.phonenumber) {
      udpdateError('sms', 'veuillez réssayer plus tard');
      setIsLoading(PageLoadingAction.Stop);
      return;
    }
    if (verificationCode.length === 6) {
      udpdateError('sms', '');
      const verifyRequest = new VerifyOtpRequestGRPC();
      verifyRequest.setPhonenumber(loggedUserData.phonenumber.toString());
      verifyRequest.setOtp(verificationCode.toString());
      await VerifyOtpService(verifyRequest).then(
        (r) => {
          if (r.getMessage() === 'WRONG OTP') {
            udpdateError('sms', 'code de vérification invalide');
            return;
          }
          router.push({
            query: {
              ...router.query,
              screen: 'new-password',
            },
          });
        },
        (_err) => {
          udpdateError('sms', 'code de vérification invalide');
        }
      );
    } else {
      udpdateError('sms', 'code verification must contains 6 number');
    }

    setIsLoading(PageLoadingAction.Stop);
  };

  const getUserData = async (kcid: string, username: string) => {
    const req = new UserByIDRequest();
    req.setKcid(kcid);
    const userData = await GetUserById(req);
    const userFormat: User = {
      avatar: userData.getUser()?.getCurrentstate()?.getAvatar(),
      kcid: userData?.getUser()?.getKcid(),
      productType: userData?.getUser()?.getProducttype(),
      userType: userData?.getUser()?.getUsertype(),
      phonenumber: userData.getUser()?.getCurrentstate()?.getPhonenumber(),
      shopBackground: userData.getUser()?.getShopbackgroundimage(),
      fullname: userData.getUser()?.getCurrentstate()?.getFullname(),
      email: userData.getUser()?.getCurrentstate()?.getEmail(),
      username,
    };

    if (userFormat) {
      setUserData(userFormat);
    }
  };

  const loginKeycloak = () => {
    const { redirect } = router.query;
    setIsLoading(PageLoadingAction.Start);
    udpdateError('sms', '');

    let keycloakUsername = identifiant;
    if (loggedUserData !== undefined)
      keycloakUsername =
        loggedUserData.keycloakusername !== ''
          ? loggedUserData?.keycloakusername
          : identifiant;
    const req = new LoginRequest();
    req.setPassword(password);
    req.setUsername(keycloakUsername);
    login(req).then(
      async (res) => {
        if (res.error) {
          udpdateError(
            'password',
            'les informations d `identification invalides'
          );
          setIsLoading(PageLoadingAction.Stop);
          return;
        }

        resetError();

        const user: any = jwt_decode(res.access_token || '');
        user.user_id = user.sub.kcid;
        await getUserData(user.sub.kcid, user?.sub?.currentstate?.username);

        JSCookies.set('userid', user.sub.kcid, {
          expires: dayjs().add(30, 'day').toDate(),
          secure: true,
          sameSite: 'Strict',
        });
        setAuthToken(res.access_token || '');
        setRefresh_token(res.refresh_token || '');
        setExpires_in(res.refresh_expires_in);
        setRefresh_expires_in(res.refresh_expires_in);
        // @ts-ignore
        if (window.rudderanalytics?.track) {
          window.rudderanalytics.track('Login Successful');
          // @ts-ignore
          window.rudderanalytics?.identify(user.sub.kcid, {
            id: user.sub.kcid,
            name: user.sub.currentstate.fullname,
            phonenumber: user.sub.currentstate.phone_number,
          });
        }
        setIsLoading(PageLoadingAction.Stop);
        if (redirect && redirect !== '') {
          await router.push(`/${redirect}`);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (_error: any) => {
        udpdateError(
          'password',
          "les informations d'identification invalides "
        );
        setIsLoading(PageLoadingAction.Stop);
      }
    );
  };

  const clickForgetPassword = () => {
    if (loggedUserData) {
      setIdentifiantForForgetPassword(loggedUserData);

      router.replace({
        pathname: '/recover-password',
      });
    } else {
      udpdateError(
        'password',
        'Oops! problème de connexion, veuillez réessayer plus tard '
      );
    }
  };
  const externalAuthentication = async (token: string | string[]) => {
    const user: any = jwt_decode(<string>token || '');
    const user_id = typeof user.sub === 'string' ? user.sub : user.sub.kcid;
    const fullname =
      typeof user.sub === 'string' ? user.name : user.sub.currentstate.fullname;
    const phone_number =
      typeof user.sub === 'string'
        ? user.phone_number
        : user.sub.currentstate.phonenumber;
    await getUserData(user_id, user.sub.currentstate.username);
    JSCookies.set('userid', user.sub.kcid, {
      expires: dayjs().add(30, 'day').toDate(),
      secure: true,
      sameSite: 'Strict',
    });
    // @ts-ignores
    setAuthToken((token as string) || '');
    setExpires_in(user.exp);
    setRefresh_token((token as string) || '');
    setRefresh_expires_in(user.exp);
    // @ts-ignore
    if (window.rudderanalytics?.track) {
      window.rudderanalytics.track('External Login Success');
      // @ts-ignore
      window.rudderanalytics?.identify(user_id, {
        id: user_id,
        name: fullname,
        phonenumber: phone_number,
      });
    }
  };
  const logout = () => {
    setExpires_in(null);
    setAuthToken('');
    setRefresh_expires_in(null);
    setRefresh_token(null);
    setError({});
    JSCookies.remove('userid');
    router.push({
      pathname: '/',
    });
  };

  const verifyRefrechTokenAndRefrech = () => {
    refrechToken();
  };
  return {
    identifiant,
    setIdentifiant,
    logout,
    password,
    setPassword,
    verificationCode,
    setVerificationCode,
    error,
    setError,
    verifyLastUserConnexion,
    sendOtpSms,
    loggedUserData,
    VerifyOtpSms,

    loginKeycloak,
    refrechToken,
    refresh_expires_in,
    newPassword,
    setnewPassword,
    newRePassword,
    setnewRePassword,
    updatePassword,
    clickForgetPassword,
    verifyRefrechTokenAndRefrech,
    externalAuthentication,
  };
};

export default useLoginHook;

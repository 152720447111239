/* eslint-disable react-hooks/exhaustive-deps */
import { UrlObject } from 'url';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import { isEmpty, parseInt } from 'lodash-es';
import { useRouter } from 'next/router';

import {
  SearchRequestObject,
  ELASTIC_FETCH_LIMIT,
  FilterRequestObject,
  RangeAdParamsRequestObject,
} from '@/api/elasticsearch/models';
import { getGoldenAd } from '@/api/fetch/rest-marketplace-api';
import { searchElasticApi } from '@/api/fetch/rest-search-api';
import {
  categories as categoryHelpers,
  getProductTypeName,
} from '@/lib/helpers';
import { pageLoading, PageLoadingAction } from '@/lib/store';
import {
  searchedListingsAtom,
  SearchedListingsAction,
  FilterAtom,
  SearchQueryAtom,
  SearchedListingsStore,
  goldenListingAtom,
  Filter,
} from '@/modules/core/store/store';
import { SelectOption } from '@/tayara-kit/Select';

import useSearchHistory from './useSearchHistory';
import { IListingItem } from '../types';
import { genSearchLink } from '../utils/utils';

export const objectNotEmpty = (obj: any): boolean => {
  if (obj !== undefined) {
    return Object.keys(obj).length !== 0;
  }
  return false;
};
const notUndefinedAndEmpty = (x: string | undefined): boolean =>
  x !== undefined && x !== '';
type NextRouterPush = (
  url: UrlObject | string,
  as?: UrlObject | string,
  options?:
    | {
        shallow?: boolean;
        locale?: string | false;
        scroll?: boolean;
      }
    | undefined
) => Promise<boolean>;
export const setUrlQueryParameters = (
  push: NextRouterPush,
  filter: Filter,
  searchQuery?: string,
  page?: number
) => {
  const queryParamsObject: any = {};

  if (searchQuery !== undefined && searchQuery !== '') {
    queryParamsObject.q = searchQuery;
  }
  if (page !== undefined && page > 1) {
    queryParamsObject.page = page; // Set the page parameter
  }
  if (filter.governorate !== undefined)
    queryParamsObject.governorate = filter.governorate;
  if (filter.delegation !== undefined) {
    queryParamsObject.delegation = filter.delegation.join(',');
  }
  if (filter.categoryName !== undefined)
    queryParamsObject.category = filter.categoryName;

  if (filter.subCategoryName !== undefined)
    queryParamsObject.subCategory = filter.subCategoryName;

  if (filter.minPrice !== undefined)
    queryParamsObject.minPrice = filter.minPrice;
  if (filter.maxPrice !== undefined)
    queryParamsObject.maxPrice = filter.maxPrice;
  if (filter.productType !== undefined) {
    let productTypeNameUri = '';
    filter.productType.forEach((productType, i) => {
      const productTypeName = getProductTypeName(productType);
      productTypeNameUri += (i > 0 ? ',' : '') + productTypeName;
    });

    queryParamsObject.productType = productTypeNameUri;
  }

  if (filter.adParams !== undefined)
    Object.keys(filter.adParams).forEach((key) => {
      queryParamsObject[key] = filter.adParams[key];
    });

  if (filter.rangeAdParams !== undefined)
    Object.keys(filter.rangeAdParams).forEach((key) => {
      const selectedMin = filter.rangeAdParams![key]?.selectedMin;
      const selectedMax = filter.rangeAdParams![key]?.selectedMax;
      // const selectedMinExists = notUndefinedAndEmpty(selectedMin);
      // const selectedMaxExists = notUndefinedAndEmpty(selectedMax);
      if (notUndefinedAndEmpty(selectedMin))
        queryParamsObject[`min${key}`] = selectedMin;
      if (notUndefinedAndEmpty(selectedMax))
        queryParamsObject[`max${key}`] = selectedMax;
    });

  const searchLink = genSearchLink(queryParamsObject);
  delete queryParamsObject.q;
  delete queryParamsObject.governorate;
  delete queryParamsObject.delegation;
  delete queryParamsObject.category;
  delete queryParamsObject.subCategory;
  delete queryParamsObject.productType;

  push({
    pathname: searchLink,
    query: queryParamsObject,
  });
};

interface ElasticsearchFetch {
  limit: number;
  offset: number;
  query: string;
  page: number;
  sort: string;
  filter: Filter;
  isDefaultListings: boolean;
}
interface InitSearchWith {
  type: 'default' | 'filter' | 'sort';
  filter?: Filter;
  sort?: string | null;
  query?: string;
}
export const initSearch = async (
  searchedListingsStore: SearchedListingsStore,
  setSearchedListingsStore: (update: SearchedListingsAction) => void,
  initSearchWith: InitSearchWith
): Promise<[IListingItem[], IListingItem[]]> => {
  let elasticsearchFetch: ElasticsearchFetch;

  if (initSearchWith.type === 'default') {
    elasticsearchFetch = {
      page: 0,
      offset: 0,
      limit: ELASTIC_FETCH_LIMIT,
      query: '',
      sort: '',
      filter: {},
      isDefaultListings: true,
    };
  } else if (initSearchWith.type === 'filter') {
    const newSearchQuery =
      initSearchWith.query !== undefined
        ? initSearchWith.query
        : searchedListingsStore.query;

    elasticsearchFetch = {
      page: 0,
      offset: 0,
      limit: searchedListingsStore.limit,
      query: newSearchQuery,
      sort: searchedListingsStore.sort!,
      filter: initSearchWith.filter!,
      isDefaultListings: false,
    };
  } else {
    // } else if (initSearchWith.type === 'sort') {
    elasticsearchFetch = {
      page: 0,
      offset: 0,
      limit: ELASTIC_FETCH_LIMIT,
      query: searchedListingsStore.query,
      sort: initSearchWith.sort!,
      filter: initSearchWith.filter!,
      isDefaultListings: searchedListingsStore.isDefaultListings,
    };
  }
  const filterRequest: FilterRequestObject = {
    categoryId: '',
    subCategoryId: '',
    adParamsMap: {},
    rangeAdParamsMap: {},
    governorate: '',
    delegation: [''],
    minPrice: 0,
    maxPrice: 0,
    productTypeList: [],
    level: 0,
    state: 0,
  };
  const searchRequest: SearchRequestObject = {
    query: '',
    offset: 0,
    limit: 30,
    sort: 0,
    filter: filterRequest,
  };

  searchRequest.limit = elasticsearchFetch.limit;
  searchRequest.offset = elasticsearchFetch.offset;
  if (!isEmpty(elasticsearchFetch.sort)) {
    searchRequest.sort = parseInt(elasticsearchFetch.sort);
  }
  if (!isEmpty(elasticsearchFetch.filter?.delegation))
    filterRequest.delegation = elasticsearchFetch.filter?.delegation!;
  if (!isEmpty(elasticsearchFetch.filter?.governorate))
    filterRequest.governorate = elasticsearchFetch.filter?.governorate!;
  if (!isEmpty(elasticsearchFetch.filter?.categoryId))
    filterRequest.categoryId = elasticsearchFetch.filter?.categoryId!;
  if (elasticsearchFetch.filter?.maxPrice! > 0)
    filterRequest.maxPrice = elasticsearchFetch.filter?.maxPrice!;
  if (elasticsearchFetch.filter?.minPrice! > 0)
    filterRequest.minPrice = elasticsearchFetch.filter?.minPrice!;
  if (!isEmpty(elasticsearchFetch.filter?.productType))
    filterRequest.productTypeList = elasticsearchFetch.filter?.productType!;

  if (!isEmpty(elasticsearchFetch.filter?.adParams)) {
    filterRequest.adParamsMap = elasticsearchFetch.filter?.adParams;
  }

  if (
    elasticsearchFetch.filter?.rangeAdParams! &&
    !isEmpty(elasticsearchFetch.filter?.rangeAdParams!)
  ) {
    Object.keys(elasticsearchFetch.filter?.rangeAdParams!).forEach((k) => {
      if (
        elasticsearchFetch.filter?.rangeAdParams &&
        elasticsearchFetch.filter?.rangeAdParams[k]
      ) {
        const rangePrams: RangeAdParamsRequestObject = {
          min: '',
          max: '',
        };
        if (
          elasticsearchFetch.filter?.rangeAdParams![k] &&
          !isEmpty(elasticsearchFetch.filter?.rangeAdParams[k]?.selectedMin)
        )
          rangePrams.min =
            elasticsearchFetch.filter?.rangeAdParams[k]?.selectedMin || '0';

        if (
          elasticsearchFetch.filter?.rangeAdParams![k] &&
          !isEmpty(elasticsearchFetch.filter?.rangeAdParams[k]?.selectedMax)
        )
          rangePrams.max =
            elasticsearchFetch.filter?.rangeAdParams[k]?.selectedMax || '0';

        filterRequest.rangeAdParamsMap[k] = rangePrams;
      }
    });
  }

  filterRequest.productTypeList = elasticsearchFetch.filter?.productType!;
  filterRequest.state = 2;
  if (
    elasticsearchFetch.filter?.subCategoryIds &&
    elasticsearchFetch.filter?.subCategoryIds[0] &&
    !isEmpty(elasticsearchFetch?.filter?.subCategoryIds[0])
  )
    filterRequest.subCategoryId = elasticsearchFetch.filter.subCategoryIds[0]!;

  if (!isEmpty(elasticsearchFetch.query)) {
    searchRequest.query = elasticsearchFetch.query;
  }

  const [[newHits, totalHitsCount], [newHitsPremieum]] = await searchElasticApi(
    searchRequest,
    true
  );
  // console.log("newHits", newHits);

  setSearchedListingsStore({
    newHits,
    type: 'init',
    query: elasticsearchFetch.query,
    sort: elasticsearchFetch.sort,
    meilisearchFilterList: [],
    elasticsearchFilterList: elasticsearchFetch.filter!,
    premiumHits: newHitsPremieum,
    totalHitsCount,
    isDefaultListings: elasticsearchFetch.isDefaultListings,
    isSSR: false,
  });
  return [newHits, newHitsPremieum];
};

export const useElastisearchFilter = (): [() => void] => {
  const { push } = useRouter();
  const [searchedListingsStore, setSearchedListingsStore] =
    useAtom(searchedListingsAtom);
  const [filter] = useAtom(FilterAtom);
  const [searchQueryStore] = useAtom(SearchQueryAtom);

  const initSearchWithFilter = async () => {
    setUrlQueryParameters(push, filter, searchQueryStore);
    await initSearch(searchedListingsStore, setSearchedListingsStore, {
      type: 'filter',
      filter,
      query: searchQueryStore,
    });
  };

  return [initSearchWithFilter];
};

export const useElasticSearchSort = (): [
  SelectOption[],
  (x: string) => Promise<void>
] => {
  const [searchedListingsStore, setSearchedListingsStore] =
    useAtom(searchedListingsAtom);
  const [, setSort] = useState<string | null>(null);

  const SORT_OPTIONS: SelectOption[] = [
    { label: 'Plus récentes', value: 0 },
    { label: 'Plus anciennes', value: 1 },
    { label: 'Prix croissant', value: 2 },
    { label: 'Prix décroissant', value: 3 },
  ];
  const [filter] = useAtom(FilterAtom);

  const handleSortSelect = async (newValue: string) => {
    const newSort = newValue !== 'default' ? newValue : null;
    setSort(newSort);
    await initSearch(searchedListingsStore, setSearchedListingsStore, {
      type: 'sort',
      sort: newSort,
      filter,
    });
  };

  return [SORT_OPTIONS, handleSortSelect];
};

export const useElastiSearchAdList = (
  ssrSearchedListingsAction?: SearchedListingsAction,
  ssrGoldenListing?: IListingItem | null
) => {
  const { pathname } = useRouter();
  const [searchedListingsStore, setSearchedListingsStore] =
    useAtom(searchedListingsAtom);

  const [goldenListingStore, setGoldenListingStore] =
    useAtom(goldenListingAtom);
  const [goldenListing, setGoldenListing] = useState<IListingItem | null>(
    ssrGoldenListing !== undefined && ssrGoldenListing !== null
      ? ssrGoldenListing
      : goldenListingStore
  );
  useEffect(() => {
    // TODO: remove pathname !== '/' when implementing SSR in homepage
    if (
      ssrGoldenListing !== undefined &&
      ssrGoldenListing !== null &&
      pathname !== '/'
    )
      setGoldenListingStore(ssrGoldenListing);
  }, [ssrGoldenListing]);

  const [premiumListings, setPremiumListings] = useState<IListingItem[]>(
    ssrSearchedListingsAction !== undefined &&
      ssrSearchedListingsAction.premiumHits !== undefined
      ? ssrSearchedListingsAction.premiumHits
      : []
  );
  // console.log("ssrSearchedListingsAction", ssrSearchedListingsAction);

  const [listings, setListings] = useState<IListingItem[]>(
    ssrSearchedListingsAction !== undefined && ssrSearchedListingsAction.isSSR
      ? ssrSearchedListingsAction.newHits
      : []
  );

  const [isLoading, setIsLoading] = useAtom(pageLoading);

  const handleLoadMore = async () => {
    if (isLoading) return;
    const continueSearch =
      searchedListingsStore.hits.length < searchedListingsStore.totalHitsCount;
    if (continueSearch) {
      setIsLoading(PageLoadingAction.Start);

      const filterRequest: FilterRequestObject = {
        categoryId: '',
        subCategoryId: '',
        adParamsMap: {},
        rangeAdParamsMap: {},
        governorate: '',
        delegation: [''],
        minPrice: 0,
        maxPrice: 0,
        productTypeList: [],
        level: 0,
        state: 0,
      };
      const searchRequest: SearchRequestObject = {
        query: '',
        offset: 0,
        limit: 0,
        sort: 0,
        filter: filterRequest,
      };
      searchRequest.limit = searchedListingsStore.limit;
      searchRequest.offset = searchedListingsStore.offset;
      console.log('searchOffset', searchRequest.offset);
      if (!isEmpty(searchedListingsStore.elasticsearchFilterList.delegation))
        filterRequest.delegation =
          searchedListingsStore.elasticsearchFilterList.delegation!;

      if (!isEmpty(searchedListingsStore.elasticsearchFilterList.governorate))
        filterRequest.governorate =
          searchedListingsStore.elasticsearchFilterList.governorate!;

      if (!isEmpty(searchedListingsStore.elasticsearchFilterList.categoryId))
        filterRequest.categoryId =
          searchedListingsStore.elasticsearchFilterList.categoryId!;

      if (searchedListingsStore?.elasticsearchFilterList?.maxPrice! > 0)
        filterRequest.maxPrice =
          searchedListingsStore.elasticsearchFilterList.maxPrice!;

      if (searchedListingsStore?.elasticsearchFilterList?.minPrice! > 0)
        filterRequest.minPrice =
          searchedListingsStore.elasticsearchFilterList.minPrice!;

      if (!isEmpty(searchedListingsStore.elasticsearchFilterList.productType))
        filterRequest.productTypeList =
          searchedListingsStore.elasticsearchFilterList.productType!;

      filterRequest.state = 2;
      if (
        searchedListingsStore.elasticsearchFilterList?.subCategoryIds &&
        searchedListingsStore.elasticsearchFilterList?.subCategoryIds[0] &&
        !isEmpty(
          searchedListingsStore.elasticsearchFilterList?.subCategoryIds[0]
        )
      )
        filterRequest.subCategoryId =
          searchedListingsStore.elasticsearchFilterList?.subCategoryIds[0]!;

      if (!isEmpty(searchedListingsStore.sort)) {
        searchRequest.sort = parseInt(searchedListingsStore?.sort || '0');
      }
      if (!isEmpty(searchedListingsStore.query)) {
        searchRequest.query = searchedListingsStore.query;
      }

      if (!isEmpty(searchedListingsStore.elasticsearchFilterList?.adParams)) {
        filterRequest.adParamsMap =
          searchedListingsStore.elasticsearchFilterList?.adParams;
      }

      if (
        searchedListingsStore.elasticsearchFilterList?.rangeAdParams! &&
        !isEmpty(searchedListingsStore.elasticsearchFilterList?.rangeAdParams)
      ) {
        Object.keys(
          searchedListingsStore?.elasticsearchFilterList?.rangeAdParams
        ).forEach((k) => {
          if (
            searchedListingsStore.elasticsearchFilterList?.rangeAdParams &&
            searchedListingsStore.elasticsearchFilterList?.rangeAdParams[k]
          ) {
            const rangePrams: RangeAdParamsRequestObject = {
              min: '',
              max: '',
            };
            if (
              searchedListingsStore?.elasticsearchFilterList?.rangeAdParams[
                k
              ] &&
              !isEmpty(
                searchedListingsStore?.elasticsearchFilterList?.rangeAdParams[k]
                  ?.selectedMin
              )
            )
              rangePrams.min =
                searchedListingsStore.elasticsearchFilterList?.rangeAdParams[k]
                  ?.selectedMin || '0';

            if (
              searchedListingsStore?.elasticsearchFilterList?.rangeAdParams[
                k
              ] &&
              !isEmpty(
                searchedListingsStore.elasticsearchFilterList?.rangeAdParams[k]
                  ?.selectedMax
              )
            )
              rangePrams.max =
                searchedListingsStore.elasticsearchFilterList?.rangeAdParams[k]
                  ?.selectedMax || '0';

            filterRequest.rangeAdParamsMap[k] = rangePrams;
          }
        });
      }

      if (!isEmpty(filterRequest)) searchRequest.filter = filterRequest;

      const [[newHits, totalHitsCount], [newHitsPremieum]] =
        await searchElasticApi(searchRequest, true);
      setSearchedListingsStore({
        type: 'inc',
        newHits,
        totalHitsCount,
        premiumHits: newHitsPremieum,
        sort: searchedListingsStore.sort,
        isSSR: false,
      });
      setListings(newHits);
      setIsLoading(PageLoadingAction.Stop);
    }
  };

  //  console.log("listing", listings);

  const setDefaultListings = async () => {
    setIsLoading(PageLoadingAction.Start);

    const [newHits, newPremiumHits] = await initSearch(
      searchedListingsStore,
      setSearchedListingsStore,
      { type: 'default' }
    );
    // console.log("newHits", newHits);

    setListings(newHits);
    setPremiumListings(newPremiumHits);

    setIsLoading(PageLoadingAction.Stop);
  };
  // filter buttion was clicked
  useEffect(() => {
    if (
      searchedListingsStore.isInitialSearch &&
      !searchedListingsStore.isDefaultListings
    ) {
      setListings(searchedListingsStore.hits);
      setPremiumListings(searchedListingsStore.premiumHits);
    }
  }, [searchedListingsStore]);

  useEffect(() => {
    const firstConnect =
      searchedListingsStore.hits.length === 0 &&
      searchedListingsStore.isDefaultListings;
    const navigatedBackToHomeFromFilter =
      pathname === '/' && !searchedListingsStore.isDefaultListings;
    const inSearchPath = pathname === '/search' || pathname.startsWith('/ads');

    if (firstConnect && inSearchPath) {
      // Wait for the query params to be read in `useSelectFilter()` and then
      // set the listings with filters from the query params
    } else if (
      (firstConnect || navigatedBackToHomeFromFilter) &&
      !ssrSearchedListingsAction?.isSSR
    ) {
      setDefaultListings();
    }
  }, [pathname]);

  const getAndSetGoldenListingStore = async (category: string | undefined) => {
    const newGoldenListing = await getGoldenAd(category || null);
    setGoldenListing(newGoldenListing);
    setGoldenListingStore(newGoldenListing);
  };
  useEffect(() => {
    // TODO: remove this when implementing SSR in homepage
    // visiting from `/` (home page) [No SSR implemented for homepage yet]
    getAndSetGoldenListingStore(
      searchedListingsStore?.elasticsearchFilterList?.categoryId
    );

    // This will cover the case when someone:
    if (
      ssrSearchedListingsAction !== undefined &&
      !searchedListingsStore.isSSR &&
      searchedListingsStore.hits.length === 0
    ) {
      // first open the page from a browser

      setSearchedListingsStore(ssrSearchedListingsAction);
    } else if (listings.length === 0 && searchedListingsStore.hits.length > 0) {
      // navigate out of AdList_ and then comes back

      setListings(searchedListingsStore.hits);
      setPremiumListings(searchedListingsStore.premiumHits);
    }
  }, [searchedListingsStore, ssrSearchedListingsAction, listings]);

  return {
    listings,
    premiumListings,
    goldenListing,
    searchedListingsStore,
    handleLoadMore,
  };
};

export const useElasticSearchSearchInput = (): [
  string,
  Dispatch<SetStateAction<string>>,
  (e: React.FormEvent) => Promise<void>,
  () => void
] => {
  const { push } = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryStore, setSearchQueryStore] = useAtom(SearchQueryAtom);
  const [searchedListingsStore, setSearchedListingsStore] =
    useAtom(searchedListingsAtom);
  const [filter] = useAtom(FilterAtom);
  const { prependSearchQuery } = useSearchHistory();
  const initSearchWithQuery = async (e: React.FormEvent) => {
    // Dont refresh page
    e.preventDefault();
    setSearchQueryStore(searchQuery);

    setUrlQueryParameters(push, filter, searchQuery);
    await initSearch(searchedListingsStore, setSearchedListingsStore, {
      type: 'filter',
      filter,
      query: searchQuery,
    });
    prependSearchQuery(searchQuery);
  };

  // Used to change the other search bar
  const handleSearchInputOnBlur = () => {
    setSearchQueryStore(searchQuery);
  };
  useEffect(() => {
    setSearchQuery(searchQueryStore);
  }, [searchQueryStore]);
  useEffect(() => {
    setSearchQuery(searchedListingsStore.query);
  }, [searchedListingsStore.query]);

  // to fix reset filter
  useEffect(() => {
    if (searchQueryStore === '') {
      setSearchQuery('');
    }
  }, [searchQueryStore]);

  return [
    searchQuery,
    setSearchQuery,
    initSearchWithQuery,
    handleSearchInputOnBlur,
  ];
};

export const useElasticSearchKeyCategories = (): [
  (selectedCategoryLink: string) => void,
  () => void
] => {
  const [searchedListingsStore, setSearchedListingsStore] =
    useAtom(searchedListingsAtom);

  const handleCategoryClick = async (selectedCategoryLink: string) => {
    // get category id
    const categoryId = categoryHelpers.find(
      (categoryItem) => categoryItem.link === selectedCategoryLink
    )?.id;

    // get subCategory ids
    // const subCategoryIds = categoryHelpers
    //   .filter((categoryItem) => categoryItem.parentcategoryxid === categoryId)
    //   .map(({ id }) => id) as string[];

    const filter: Filter = { categoryId };

    await initSearch(searchedListingsStore, setSearchedListingsStore, {
      type: 'filter',
      filter,
    });
  };

  const handleNeufClick = async () => {
    const filter: Filter = { productType: [1] };
    await initSearch(searchedListingsStore, setSearchedListingsStore, {
      type: 'filter',
      filter,
    });
  };
  return [handleCategoryClick, handleNeufClick];
};

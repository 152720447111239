const RUDDERSTACK_DATA_PLANE_URL = 'https://rudderstack.tayara.tn';
const RUDDERSTACK_KEY_BASE64 = 'MjdDM2FFVHBINEFmakU4YnRIVXd3RFZrZkFKOg==';

const trackAdImpression = async (adIds: string[]) => {
  const currentTimestamp = new Date().toISOString();
  const reqBody = adIds.map((adId: string) => ({
    type: 'track',
    anonymousId: 'N/A',
    event: 'Listing Impression Test - 2',
    properties: {
      id: adId,
    },
    timestamp: currentTimestamp,
  }));
  const res = await fetch(`${RUDDERSTACK_DATA_PLANE_URL}/v1/batch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${RUDDERSTACK_KEY_BASE64}`,
    },
    body: JSON.stringify({ batch: reqBody }),
  });
  const resJson = await res.json();
  if (!res.ok) throw new Error(JSON.stringify(resJson));
};

const rudderstackApi = {
  trackAdImpression,
};

export const rudderstackTrackEvent = (...props: any[]) => {
  if (window.rudderanalytics?.track) {
    window.rudderanalytics?.track(...props);
  } else {
    // await for rudderanalytics to load
    const t = setInterval(() => {
      if (window.rudderanalytics?.track) {
        clearInterval(t);
        window.rudderanalytics?.track(...props);
      }
    }, 1000);
  }
};

export const rudderstackIdentify = (...props: any[]) => {
  if (window.rudderanalytics?.identify) {
    window.rudderanalytics?.identify(...props);
  } else {
    // await for rudderanalytics to load
    const t = setInterval(() => {
      if (window.rudderanalytics?.identify) {
        clearInterval(t);
        window.rudderanalytics?.identify(...props);
      }
    }, 1000);
  }
};

export default rudderstackApi;

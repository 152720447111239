import { useEffect, useState } from 'react';

import { useAtom } from 'jotai';

import { GetAdsByUser, getCountAds } from '@/api/fetch/marketplace-api';
import {
  AdsListAtom,
  PrivateFilterAtom,
  PrivateSearchFilterType,
  PrivateSearchListAction,
  PrivateSearchListStore,
} from '@/modules/core/store/store';
import {
  FilterPrivateAdsType,
  IListingItem,
  ListingState,
} from '@/modules/core/types';
import { SelectOption } from '@/tayara-kit/Select';

import { LIMIT, SHOW_ALL_ADS } from '../constants';
import { pageLoading, PageLoadingAction } from '../store';

interface FilterObject {
  filterDefault: PrivateSearchFilterType;
  limit: number;
  skip: number;
  userid: string;
}

const privateSearchListFunction = async (
  adsListAtom: PrivateSearchListStore,
  setAdsListAtom: (update: PrivateSearchListAction) => void,
  filter: FilterObject,
  isPublic: boolean,

  type: 'init' | 'filter'
) => {
  let filterParams: FilterPrivateAdsType;
  if (type === 'init') {
    filterParams = {
      isPublic,
      limit: LIMIT,
      category: '',
      status: SHOW_ALL_ADS,
      userId: filter.userid,

      skip: 0,
    };
  } else {
    filterParams = {
      isPublic,
      limit: LIMIT,
      category: filter.filterDefault.category,

      status: filter.filterDefault.status,

      userId: filter.userid || adsListAtom.userid,

      skip: 0,
    };
  }

  Promise.all([GetAdsByUser(filterParams), getCountAds(filterParams)])
    .then(([listAds, totalAdsCount]) => {
      setAdsListAtom({
        newList: listAds,
        query: '',
        sort: '',
        totalListCount: totalAdsCount.getCount(),
        type,
        userid: filterParams.userId,
        isPublic,
        status: filterParams.status,
      });
    })
    .catch(() => {
      setAdsListAtom({
        newList: [],

        totalListCount: 0,
        type: 'init',
        userid: filterParams.userId,
        isPublic,
        status: filterParams.status,
      });
    });
};

export interface UseAdsInfiniteProps {
  userId: string;
  initialCountTotalAds?: number;
  isPublic: boolean;
  initialAdslistData?: IListingItem[];
}

// custom hooks to handle infinite scroll ads data
export const useAdsInfinite = ({
  userId,
  isPublic,
  initialAdslistData,
  initialCountTotalAds,
}: UseAdsInfiniteProps) => {
  const [isLoading, setIsLoading] = useAtom(pageLoading);
  const [adsListAtom, setAdsListAtom] = useAtom(AdsListAtom);
  const [FilterAtom] = useAtom(PrivateFilterAtom);

  const getUserAds = async () => {
    setIsLoading(PageLoadingAction.Start);

    const FilterObject: FilterObject = {
      filterDefault: {
        category: FilterAtom.category,
        delegation: FilterAtom.delegation,
        governorate: FilterAtom.governorate,
        status: FilterAtom.status,
        subcategory: FilterAtom.subcategory,
      },
      limit: LIMIT,
      skip: 0,
      userid: userId,
    };
    await privateSearchListFunction(
      adsListAtom,
      setAdsListAtom,
      FilterObject,
      isPublic,
      'init'
    );

    setIsLoading(PageLoadingAction.Stop);
  };

  useEffect(() => {
    if (
      initialAdslistData &&
      initialCountTotalAds &&
      initialCountTotalAds > 0
    ) {
      setAdsListAtom({
        newList: initialAdslistData,

        totalListCount: initialCountTotalAds,
        type: 'init',
        userid: userId,
        isPublic,
        status: SHOW_ALL_ADS,
      });
    } else if (userId) getUserAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const loadMoreAdsData = async () => {
    if (adsListAtom.list.length >= adsListAtom.totalListCount) return;
    if (isLoading) return;
    const filterParams = {
      isPublic,
      limit: LIMIT,
      category: '',
      status: FilterAtom.status,
      userId,

      skip: adsListAtom.offset + LIMIT,
    };
    const newListAd = await GetAdsByUser(filterParams);
    if (newListAd)
      setAdsListAtom({
        newList: newListAd,

        type: 'inc',
      });
  };

  return [adsListAtom.list, adsListAtom.totalListCount, loadMoreAdsData] as [
    IListingItem[],
    number,
    () => {}
  ];
};

export const useListingsFilterSort = () => {
  const [adsListAtom, setAdsListAtom] = useAtom(AdsListAtom);
  const [FilterAtom, setFilterAtom] = useAtom(PrivateFilterAtom);
  const [, setIsLoading] = useAtom(pageLoading);

  const SORT_OPTIONS: SelectOption[] = [
    { label: 'Plus récentes', value: 'creationtimestamp:desc' },
    { label: 'Plus anciennes', value: 'creationtimestamp:asc' },
  ];

  const FILTER_OPTIONS: SelectOption[] = [
    { label: 'Afficher tout', value: SHOW_ALL_ADS },
    { label: 'Afficher Active', value: ListingState.ACTIVE },
    { label: 'Afficher Vendue', value: ListingState.SOLD },
    { label: 'Afficher Supprimée', value: ListingState.DELETED },
    { label: 'Afficher Expirée', value: ListingState.EXPIRED },
    { label: 'Afficher Rejetée', value: ListingState.REJECTED },
    {
      label: 'Afficher En modération',
      value: ListingState.PENDING_MOD,
    },
  ];

  const [, setSort] = useState('creationtimestamp:desc');

  const setFilterState = async (newState: ListingState) => {
    setFilterAtom((p) => ({
      ...p,
      status: newState,
    }));

    setIsLoading(PageLoadingAction.Start);

    const FilterObject: FilterObject = {
      filterDefault: {
        category: FilterAtom.category,
        delegation: FilterAtom.delegation,
        governorate: FilterAtom.governorate,
        status: newState,
        subcategory: FilterAtom.subcategory,
      },
      limit: LIMIT,
      skip: 0,
      userid: adsListAtom.userid,
    };

    await privateSearchListFunction(
      adsListAtom,
      setAdsListAtom,
      FilterObject,
      false,
      'filter'
    ).finally(() => setIsLoading(PageLoadingAction.Stop));
  };

  return {
    SORT_OPTIONS,
    FILTER_OPTIONS,
    setSort,
    setFilterState,
  };
};

import { useEffect } from 'react';

export interface UseGoogleAdsTargetingProps {
  [key: string]: string;
}

export function useGoogleAdsTargeting(targeting: UseGoogleAdsTargetingProps) {
  useEffect(() => {
    (async () => {
      const { googletag } = window;

      await new Promise((res) => {
        const i = setInterval(() => {
          if (googletag.pubads) {
            clearInterval(i);
            res(undefined);
          }
        }, 100);
      });

      const pubads = googletag?.pubads();

      pubads?.clearTargeting();
      Object.keys(targeting || {}).forEach((key) => {
        if (targeting) pubads?.setTargeting(key, targeting[key]);
      });
    })();
  }, [targeting]);
}

export function GoogleAdsTargeting(props: UseGoogleAdsTargetingProps) {
  useGoogleAdsTargeting(props);
  return null;
}

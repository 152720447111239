import { UrlObject } from 'url';

import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';

import { SetStateAction, useAtom } from 'jotai';
import { flatMap } from 'lodash-es';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IconType } from 'react-icons';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import { BiMessageAlt, BiStore } from 'react-icons/bi';
import { CgClose, CgMenu } from 'react-icons/cg';
import {
  HiOutlineCollection,
  HiOutlinePlus,
  HiOutlineShieldCheck,
  HiOutlineSparkles,
  HiOutlineUserCircle,
} from 'react-icons/hi';
import {
  IoHelpCircleOutline,
  IoLogOut,
  IoPricetagOutline,
} from 'react-icons/io5';
import {
  MdOutlineNotificationsActive,
  MdOutlineRealEstateAgent,
} from 'react-icons/md';
import { RiDashboard2Line } from 'react-icons/ri';
import { useBoolean, useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

import { CSRLazy } from '@/lib/CSRLazy';
import {
  LoadHeavyContentAtom,
  pageLoading,
  stickyBannerAtom,
} from '@/lib/store';
import useLoginHook from '@/modules/auth/hooks/useLoginHook';
import { UserType } from '@/modules/profile/types';
import MobileStickyBanner from '@/tayara-kit/banners/MobileStickyBanner';
import Button, { ButtonIconPosition } from '@/tayara-kit/Button';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import { TkT, TkTokenHistory } from '@/tayara-kit/icons';
import Text from '@/tayara-kit/Text';
import { NEXT_API_URL } from 'environment';

import HandsetNavbarSkeleton from './HandsetNavbarSkeleton';
import { SearchInput } from './SearchInput';
import { useElasticSearchKeyCategories } from '../hooks/useElasticSearchServer';
import useProfile from '../hooks/useProfileHook';

// const MobileStickyBanner = dynamic(
//   async () => import('@/tayara-kit/banners/MobileStickyBanner'),
//   {
//     ssr: false,
//   }
// );

interface IprofileSubMenu {
  name: string;
  label: string;
  href: UrlObject;
  icon: IconType;
  action?: () => void;
}

const Notification = dynamic(async () => import('@/firebase/Notification'), {
  ssr: false,
});
const Modernizr = { backdropfilter: true };

const SCROLL_SEARCH_MAX = 100;
const SCROLL_SEARCH_MIN = 0;

const HandsetNavbar = () => {
  const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);
  const [expiresIn, setExpiresIn] = useState(0);
  const exp = useReadLocalStorage<number>('expires_in');
  const headless = useReadLocalStorage('headless');
  const { pathname } = useRouter();

  const [showStickyBanner, setShowStickyBanner] = useAtom(stickyBannerAtom);

  const [lastScroll, setLastScroll] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const router = useRouter();
  const headlessParam = router.query.headless;
  useEffect(() => {
    function handleScroll() {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        setIsHidden(false);
      } else if (currentScroll > lastScroll && !isHidden) {
        setIsHidden(true);
      } else if (currentScroll < lastScroll && isHidden) {
        setIsHidden(false);
      }
      setLastScroll(currentScroll);
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHidden, lastScroll]);

  const isAuthenticated = useMemo(
    () => Date.now() / 1000 < +(expiresIn || 0),
    [expiresIn]
  );

  useEffectOnce(() => {
    let isMounted = true;
    fetch(`${NEXT_API_URL}/settings`)
      .then((res) => res.json())
      .then((settings) => {
        if (settings?.showStickyBanner) {
          if (isMounted) {
            setShowStickyBanner(true);
          }
        } else if (isMounted) {
          setShowStickyBanner(false);
        }
      });
    return () => {
      isMounted = false;
    };
  });

  // fix hydration error
  useEffect(() => {
    if (headlessParam === 'true') {
      if (window !== undefined) {
        localStorage.setItem('headless', 'true');
      }
    }
    setExpiresIn(parseInt(`${exp}`, 10));
  }, [exp, headlessParam]);

  const [isLoading] = useAtom(pageLoading);

  const [, handleNeufClick] = useElasticSearchKeyCategories();
  // const [showContent, setShowContent] = useState(true);
  const {
    // value: showSearchBar,
    setTrue: setShowSearchBarToTrue,
    // setFalse: setShowSearchBarToFalse,
  } = useBoolean(false);
  // const [prevScrollPos, setPrevScrollPos] = useState(0);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const arrowUpContainerRef = useRef<HTMLDivElement>(null);
  const searchFocused = useRef(false);
  const searchHasValue = useRef(false);

  const setArrowUpWidth = () => {
    if (arrowUpContainerRef.current)
      arrowUpContainerRef.current.style.width =
        searchFocused.current && searchHasValue.current ? '0px' : '1px';
  };

  const setSearchFocused: Dispatch<SetStateAction<boolean>> = (v) => {
    searchFocused.current = v as boolean;
    setArrowUpWidth();
  };
  const setSearchHasValue: Dispatch<SetStateAction<boolean>> = (v) => {
    searchHasValue.current = v as boolean;
    setArrowUpWidth();
  };

  const scrollSearchPosition = useRef(
    searchFocused.current ? SCROLL_SEARCH_MAX : SCROLL_SEARCH_MIN
  );
  const scrollPosition = useRef(
    typeof window !== 'undefined' ? window.scrollY : 0
  );

  const setSearchScrollPos = () => {
    if (searchContainerRef.current)
      searchContainerRef.current.style.height = `${
        (60 * scrollSearchPosition.current) / 100
      }px`;
  };

  useEffect(() => {
    const handleScroll = () => {
      const travel = -(scrollPosition.current - window.scrollY);
      scrollPosition.current = window.scrollY;

      scrollSearchPosition.current -=
        window.scrollY < 100 ? Math.abs(travel) : travel;
      if (
        scrollSearchPosition.current > SCROLL_SEARCH_MAX ||
        searchFocused.current
      )
        scrollSearchPosition.current = SCROLL_SEARCH_MAX;
      else if (scrollSearchPosition.current < SCROLL_SEARCH_MIN)
        scrollSearchPosition.current = SCROLL_SEARCH_MIN;

      setSearchScrollPos();
    };

    if (window !== undefined) {
      setSearchScrollPos();
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (window !== undefined) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // useEffect(() => {
  //   function handleScroll() {
  //     const currentScrollPos = window.pageYOffset;
  //     const scrollDelta = currentScrollPos - prevScrollPos;
  //     setPrevScrollPos(currentScrollPos);

  //     if (scrollDelta > 0) {
  //       setShowContent(false);
  //     } else {
  //       setShowContent(true);
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollPos]);

  const {
    value: isVisible,
    setFalse: close,
    setTrue: open,
  } = useBoolean(false);
  const { logout } = useLoginHook();
  const { query } = useRouter();
  const { userData } = useProfile();
  const expiredIn = useReadLocalStorage<number>('expires_in');
  const isNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf';
  function checkos() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'web';
  }

  const track = (event: string) => {
    // @ts-ignore
    if (window.rudderanalytics?.track) {
      window.rudderanalytics.track(event, {
        platform: checkos(),
      });
    }
  };

  function SavedSearchHandler() {
    if (Date.now() / 1000 > +(+(expiredIn || 0))) {
      router.push({
        pathname: '/login',
        query: {
          redirect: '/profile/?t=myAlerts',
        },
      });
    }
  }

  const profileSubMenu: IprofileSubMenu[] = flatMap([
    userData.userType === UserType.PRO
      ? {
          name: 'Tableau de bord',
          label: 'dashboard',
          href: { pathname: '/profile/', query: { t: 'dashboard' } },
          icon: RiDashboard2Line,
          action: close,
        }
      : [],
    {
      name: 'Mes annonces',
      label: 'myListings',
      href: { pathname: '/profile/', query: { t: 'myListings' } },
      icon: HiOutlineCollection,
      action: close,
    },
    {
      name: 'Mes alertes',
      label: 'myAlerts',
      href: { pathname: '/profile/', query: { t: 'myAlerts' } },
      icon: MdOutlineNotificationsActive,
      action: close,
    },
    {
      name: 'Mes transactions',
      label: 'transactions',
      href: { pathname: '/profile/', query: { t: 'transactions' } },
      icon: TkTokenHistory,
      action: close,
    },
    {
      name: 'Mes informations',
      label: 'details',
      href: { pathname: '/profile/', query: { t: 'details' } },
      icon: HiOutlineUserCircle,
      action: close,
    },
    {
      name: 'Sécurité',
      label: 'security',
      href: { pathname: '/profile/', query: { t: 'security' } },
      icon: HiOutlineShieldCheck,
      action: close,
    },
  ]);

  const ProSubMenu: IprofileSubMenu[] = flatMap([
    {
      name: 'Nos boutiques',
      label: 'Les boutiques sur tayara.tn',
      href: { pathname: '/shops' },
      icon: BiStore,
      action: close,
    },
    {
      name: 'Produits neufs',
      label: 'Les produits neufs sur tayara.tn',
      href: { pathname: '/search', query: { productType: 'Neuf' } },
      icon: HiOutlineSparkles,
      action: close,
    },
  ]);

  const helpSubMenu: IprofileSubMenu[] = flatMap([
    {
      name: `Besoin d${"'"}aide`,
      label: 'Contact de tayara.tn',
      href: { pathname: '/contact' },
      icon: IoHelpCircleOutline,
      action: close,
    },

    {
      name: 'Offres et services',
      label: 'Les offres et services de tayara.tn',
      href: { pathname: '/services' },
      icon: IoPricetagOutline,
      action: close,
    },

    {
      name: 'ImmoNeuf',
      label: '',
      href: { pathname: '/immoNeuf' },
      icon: AiOutlineHome,
      action: close,
    },
  ]);

  if (headless) return <></>;

  return (
    <>
      {!isLoading ? (
        <>
          {isVisible && (
            <div className="z-[99999] fixed h-screen inset-0 overflow-y-auto">
              <div className="w-full h-screen bg-white overflow-hidden transform transition-all">
                <div className="bg-white ">
                  <div className="w-full py-4 flex items-center justify-between border-b-[1px] border-neutral-200/70">
                    <Link href={{ pathname: '/' }} onClick={close} passHref>
                      <img
                        className={`w-[100px] transition-all duration-300 ml-6`}
                        src={
                          isNeuf
                            ? '/media/immoExpertLogo.webp'
                            : '/media/tayara-logo.svg'
                        }
                        alt="Logo de tayara.tn"
                      />
                    </Link>
                    <button
                      className="mr-4 p-2 border border-gray-400 hover:border-gray-600 hover:bg-gray-100 rounded-md h-auto "
                      onClick={close}
                    >
                      <CgClose size={20} className="text-neutral-500" />
                    </button>
                  </div>

                  <div className="w-full h-[85vh] overflow-y-scroll flex flex-col ">
                    {isAuthenticated ? (
                      <Link
                        href={{ pathname: '/' }}
                        onClick={() => {
                          logout();
                          close();
                        }}
                        replace
                        passHref
                      >
                        <button
                          aria-label="Se déconnecter"
                          className="w-[90vw] flex items-center justify-center gap-x-2 py-2 px-2 mx-auto my-4
                 rounded-lg border-[1px] border-primary bg-white hover:bg-primary text-primary hover:text-white text-sm  font-bold font-arabic hover:!border-solid  hover:!border-neutral-400"
                        >
                          <IoLogOut size={24} className="my-1" />
                          <h3 className="py-1 lg:text-2xs md:text-2xs text-sm font-arabic font-bold md:block xl:block w-max ">
                            Se déconnecter
                          </h3>
                        </button>
                      </Link>
                    ) : (
                      <Link
                        href={{ pathname: '/login' }}
                        onClick={close}
                        replace
                        passHref
                      >
                        <button
                          aria-label="Se connecter"
                          className="w-[90vw] flex items-center justify-center gap-x-2 py-2 px-2 mx-auto my-4
                 rounded-lg border-[1px] border-neutral-500 bg-transparent text-neutral-500 hover:text-white hover:bg-neutral-500 text-sm  font-bold font-arabic hover:!border-solid  hover:!border-neutral-400"
                        >
                          <HiOutlineUserCircle size={24} className="my-1" />
                          <h3 className="py-1 lg:text-2xs md:text-2xs text-sm font-arabic font-bold md:block xl:block w-max">
                            Se connecter
                          </h3>
                        </button>
                      </Link>
                    )}
                    {/* Profile sub-menu */}
                    <div className="flex flex-col">
                      {isAuthenticated &&
                        profileSubMenu.map((item) => (
                          <Link
                            key={item.name}
                            href={item.href}
                            passHref
                            className={` font-bold 
                  text-neutral-600 active:text-neutral-800
                  group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm `}
                            aria-current={
                              item.label === query.t ? 'page' : undefined
                            }
                            onClick={() => {
                              if (item.name === 'Mes alertes') {
                                SavedSearchHandler();
                                track('Visited saved search');
                              }
                              if (item.action) {
                                item.action();
                              }
                            }}
                          >
                            <item.icon
                              className={`
                      flex-shrink-0 -ml-1 mr-3 h-10 w-6`}
                              aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                          </Link>
                        ))}
                      {isAuthenticated && (
                        <Divider
                          orientation={Orientation.HORIZONTAL}
                          className={'!mx-auto !w-[85vw] !my-2'}
                        />
                      )}
                    </div>
                    {/* Pro sub-menu */}
                    <div className="flex flex-col">
                      {ProSubMenu.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          passHref
                          className={` font-bold 
                  text-neutral-600 active:text-neutral-800
                  group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm `}
                          aria-current={
                            item.label === query.t ? 'page' : undefined
                          }
                          onClick={() => {
                            if (item.name === 'Produits neufs') {
                              handleNeufClick();
                            }
                            if (item.action) {
                              item.action();
                            }
                          }}
                        >
                          <item.icon
                            className={`
                      flex-shrink-0 -ml-1 mr-3 h-10 w-6`}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      ))}
                      <Link
                        href={'/immoNeuf'}
                        passHref
                        className={` font-bold 
                  text-neutral-600 active:text-neutral-800
                  group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm `}
                        aria-current={undefined}
                        onClick={() => close()}
                      >
                        <MdOutlineRealEstateAgent
                          className={`
                      flex-shrink-0 -ml-1 mr-3 h-10 w-6`}
                          aria-hidden="true"
                        />
                        <span className="truncate">Immobiliers neufs</span>
                      </Link>
                      <Divider
                        orientation={Orientation.HORIZONTAL}
                        className={'!mx-auto !w-[85vw] !my-2'}
                      />
                    </div>
                    {/* Help sub-menu */}
                    <div className="flex flex-col mb-10">
                      {helpSubMenu.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          passHref
                          className={` font-bold 
                  text-neutral-600 active:text-neutral-800
                  group pl-8 active:pl-10 mr-14 py-1 flex items-center justify-start rounded-e-3xl active:bg-neutral-100 text-sm `}
                          aria-current={
                            item.label === query.t ? 'page' : undefined
                          }
                          {...(item.action ? { onClick: close } : {})}
                        >
                          <item.icon
                            className={`
                      flex-shrink-0 -ml-1 mr-3 h-10 w-6 text-neutral-800`}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      ))}
                    </div>
                    <div
                      className={`absolute bottom-0 h-16 bg-white flex justify-center items-center w-full `}
                    >
                      <Link
                        href={{ pathname: '/terms/' }}
                        onClick={() => {
                          close();
                        }}
                        passHref
                      >
                        <Text
                          className="text-3xs underline text-neutral-500"
                          text={`Conditions d${"'"}utilisation`}
                        />
                      </Link>
                      <Link href={{ pathname: '/' }} onClick={close} passHref>
                        <TkT
                          className=" text-neutral-500 mx-6 mt-1"
                          size={16}
                        />
                      </Link>
                      <Link
                        href={{ pathname: '/terms/' }}
                        onClick={() => {
                          close();
                        }}
                        passHref
                      >
                        <Text
                          className="text-3xs underline text-neutral-500"
                          text={`Politiques relatives aux cookies`}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <CSRLazy fallback={''}>
            {loadHeavyContent && showStickyBanner ? <Notification /> : ''}
          </CSRLazy>
          {/* Blurry bar */}

          <div
            className={`border-b-[1px] border-neutral-200 z-[9999] fixed top-0 right-0 left-0 w-screen bg-white/90 bg-blend-overlay backdrop-blur-lg pointer-events-auto transition-transform duration-500`}
          >
            <>
              <div className="relative h-[100px] w-[280px] md:w-full md:aspect-1000/300 md:max-w-[1000px] mx-auto rounded-2xl overflow-hidden">
                {/* <button
                    tabIndex={-1}
                    className="p-1 rounded-full bg-neutral-100 text-neutral-400 absolute top-0 right-0 mr-4 mt-4 border border-neutral-300 "
                    onClick={closeGoogleAd}
                  >
                    <HiX size={19} />
                  </button> */}
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <span className=" text-2xl md:text-4xl font-extrabold text-neutral-200">
                    Publicité
                  </span>
                  <img
                    className={`w-[50px] md:w-[80px] relative -right-6 md:-right-8 transition-all duration-300 `}
                    src={
                      isNeuf
                        ? '/media/immoExpertLogo.webp'
                        : '/media/tayara-logo.svg'
                    }
                    alt="Logo de tayara.tn"
                  />
                </div>
              </div>
            </>

            {loadHeavyContent ? <MobileStickyBanner /> : <></>}
            {/* 
            <div
              className={`pointer-events-none absolute w-full bottom-[-37.5px] border-t-primary transition-transform duration-300 ${
                isLoading ? 'scale-y-100' : 'scale-y-0 translate-y-[-16px]'
              }`}
            >
              <img
                className="mx-auto mt-[-1px] flex-none"
                src="/media/loader.svg"
                alt="Logo de tayara.tn"
              />
            </div> */}
            <div className="flex justify-between items-center px-0 md:px-6 py-2 mt-2 border-t-[1px] border-neutral-200/70">
              {/* West items */}
              <div className="col-span-2 flex items-center justify-center w-full grow">
                <Link href={{ pathname: '/' }} passHref>
                  <TkT className=" py-2 text-primary" size={42} />
                </Link>
                <Divider
                  orientation={Orientation.VERTICAL}
                  className={'!h-3 !mx-1'}
                />
                <SearchInput
                  filter
                  className="drop-shadow-sm grow w-full transition-[width] !bg-transparent border-[1px] border-neutral-300 !rounded-3xl focus:!bg-neutral-600 focus:placeholder:text-white "
                  iconClasses={'text-white'}
                  setSearchFocused={setSearchFocused}
                  setSearchHasValue={setSearchHasValue}
                />
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <CgMenu
                  onClick={open}
                  size={26}
                  className="mr-4 text-neutral-500"
                />
              </div>
            </div>

            {/* {showContent && router.pathname === '/' && <NavbarLower />} */}
          </div>

          {/* South side actions (lower Navbar) */}

          <div
            className={`!z-[9999] flex w-full flex-col border-t border-gray-200 shadow-sm fixed bottom-0 left-0 right-0 transition-transform duration-700 
${Modernizr.backdropfilter ? 'bg-white/80 bg-blend-overlay' : 'bg-white'}`}
            style={{
              backdropFilter: 'blur(24px)',
              WebkitBackdropFilter: 'blur(24px)',
            }}
          >
            {/* <div
              className={`pointer-events-none absolute w-full bottom-[100%] border-t-[1px] border-t-primary transition-transform duration-300 ${
                isLoading ? '-scale-y-100' : 'scale-y-[0] translate-y-1/2'
              }`}
            >
              <img
                className="mx-auto flex-none mt-[-1px]"
                src="/media/loader.svg"
                alt="Logo de tayara.tn"
              />
            </div> */}

            {/* Search tab bar */}

            {/* {showSearchBar && (
            <div
              className={`w-full transform-gpu overflow-hidden border-b-[1px] border-gray-400 `}
            >
              <div className="w-full flex justify-start items-center flex-row my-2 mx-4  text-black/60">
                <MdKeyboardArrowDown
                  onClick={setShowSearchBarToFalse}
                  className="mx-2 mt-1"
                  size={26}
                />

                <div ref={arrowUpContainerRef} className="relative transition-[width]">
                <Button
                  aria-label="Haut de page"
                  className={`!flex !flex-col  !bg-transparent btn-sm my-1 !w-fit !px-0 text-black/60`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                >
                  <div className="flex items-center flex-col mt-1 ">
                    <HiArrowUp size={16} />
                    <span className="text-3xs truncate font-light mt-1">
                      Haut de page
                    </span>
                  </div>
                </Button>
              </div>
              </div>
            </div>
          )} */}

            <div className={`w-full font-arabic grid grid-cols-5 mb-3`}>
              <Link
                href={{
                  pathname: '/shops',
                }}
                passHref
              >
                <Button
                  aria-label="Mes recherches sauvegardes"
                  icon={<BiStore size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/shops') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Boutiques
                </Button>
              </Link>
              <Link
                className="flex justify-center"
                href={{ pathname: '/immoNeuf' }}
                shallow
                passHref
              >
                <Button
                  aria-label="ImmoNeuf"
                  onClick={setShowSearchBarToTrue}
                  icon={<AiOutlineHome size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent !truncate ${
                    pathname.indexOf('/immoNeuf') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  ImmoNeuf
                </Button>
              </Link>

              <Link
                className="flex justify-center"
                href={{ pathname: '/post-listing' }}
                shallow
                passHref
              >
                <Button
                  aria-label="Postuler annonce"
                  icon={<HiOutlinePlus size={24} />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`absolute top-1 !justify-start !items-start !mx-2 line-clamp-1 !rounded-xl !p-2 !shadow-primary !shadow-lg !bg-primary w-fit font-normal text-2xs ${
                    pathname.indexOf('/post-listing') === 0
                      ? 'text-white !outline-double !outline-offset-2 !outline-2 !outline-primary'
                      : 'text-white'
                  }`}
                >
                  Annonce
                </Button>
              </Link>
              <Link href={{ pathname: '/chat' }} shallow passHref>
                <Button
                  aria-label="Consulter les messages"
                  icon={<BiMessageAlt size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/chat') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Messages
                </Button>
              </Link>
              <Link
                href={{ pathname: isAuthenticated ? '/profile' : '/login' }}
                shallow
                passHref
              >
                <Button
                  aria-label="Profil"
                  icon={<AiOutlineUser size={24} className="my-1" />}
                  iconPosition={ButtonIconPosition.Top}
                  className={`line-clamp-1 w-full rounded-none font-normal text-2xs !bg-transparent ${
                    pathname.indexOf('/profile') === 0
                      ? 'text-primary'
                      : 'text-neutral-500'
                  }`}
                >
                  Profil
                </Button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <HandsetNavbarSkeleton />
      )}
    </>
  );
};

export default HandsetNavbar;

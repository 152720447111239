import { IconBaseProps } from 'react-icons';

interface RoundedArrowUpProps extends IconBaseProps {
  className?: string;
  size?: string | number;
}

const RoundedArrowUp: React.FC<RoundedArrowUpProps> = ({ size, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="TiTokenHistory"
    data-name="rounded-arrow-up"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120.435 137.657"
  >
    <title>rounded-arrow-up</title>
    <path
      d="M170.464,198.367v-65.54a3.2,3.2,0,0,1,3.2-3.2H192.45a2.4,2.4,0,0,0,1.732-4.056L138.1,66.984a4.794,4.794,0,0,0-6.927,0L75.088,125.575a2.4,2.4,0,0,0,1.733,4.056H95.608a3.2,3.2,0,0,1,3.2,3.2v65.54a4.8,4.8,0,0,0,4.795,4.795h62.07A4.8,4.8,0,0,0,170.464,198.367Z"
      transform="translate(-74.417 -65.505)"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default RoundedArrowUp;

// import dayjs from 'dayjs';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';

import dayjs from 'dayjs';

import { environment } from 'environment';

export function validatePhoneNumber(inputtxt: string): boolean {
  const phoneno = /^\d{8}$/;
  if (inputtxt.match(phoneno)) return true;
  return false;
}

const normalizeSrc = (src: string) => (src[0] === '/' ? src.slice(1) : src);

type Image = {
  src: string;

  width?: string;
  height?: string;
};

const imageResizerOld = ({ src, width, height }: Image): string => {
  const url = normalizeSrc(src).split('/');

  let widthParam = `&w=${width}`;
  let heightParam = `&h=${height}`;
  if (width === undefined) widthParam = '&w=256';
  if (height === undefined) heightParam = '';

  const cdnUrl = `${environment.imageresizeOld}?img=${url[url.length - 2]}/${
    url[url.length - 1]
  }${widthParam}${heightParam}&fit=pad`;
  return cdnUrl;
};

const imageResizerNew = ({ src, width }: Image): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const cdn_url = `${environment.imageresizeNew}/${src}${
    width ? `-webp-${width}x0` : ''
  }`;
  return cdn_url;
};

/**
 *  function to transfert query variables to grpc request
 * @param image Image

 * @returns  string
 */

export const transferImageResize = (image: Image): string => {
  if (image.src.startsWith('https://')) {
    return imageResizerOld(image);
  }

  return imageResizerNew(image);
};

export const cleanObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export async function fetchGames(options = {}) {
  const now: any = dayjs().toISOString();

  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer 997f0948b7c4995390f4f6d9d0591b396df4a85c4c327a98eba5d3b2ed8667a9e17f7866798b827b925f332154569b1d0a2c2df4dbaac947f6d40024171d4567b9df700823f18c1cb1229990f1e5b4eef908ba99dfc82c8eb8eac7684ec72b2279cef3987422f4f989346632da43fac99a06632483ccfd58bc211876d52f462e' ||
        '',
    },
    ...options,
  };

  const requestUrl = `${`${environment.strapiUri}/games?populate=*&filters[start][$lte]=${now}&filters[end][$gt]=${now}`}`;

  const response = await fetch(requestUrl, mergedOptions);
  // Handle response
  if (!response.ok) {
    return new Error(`An error occured please try again`);
  }
  const data = await response.json();
  return data;
}

export const objectNotEmpty = (obj: any): boolean => {
  if (obj !== undefined) {
    return Object.keys(obj).length !== 0;
  }
  return false;
};

import React, { useState, useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { HiOutlineX } from 'react-icons/hi';

import useFirebase from '@/firebase/Firebase';

const Notification = () => {
  const { requestForToken, onMessageListener } = useFirebase();

  const [notification, setNotification] = useState({
    title: '',
    body: '',
    data: { url: '' },
  });

  const notify = () =>
    toast.custom(
      (t) => (
        <>
          <a
            href={notification?.data?.url || '/'}
            className={`${
              t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="/media/t-logo-primary.png"
                    alt="tayara logo in reddish-orange color"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-lg font-medium text-gray-900">
                    {notification?.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-700">
                    {notification?.body}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-start">
              <button
                onClick={() => toast.dismiss(t.id)}
                className=" text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                <HiOutlineX size={20} />
              </button>
            </div>
          </a>
        </>
      ),
      { position: 'bottom-right', duration: 20000 }
    );

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  requestForToken().then();

  onMessageListener().then((payload) => {
    setNotification({
      // @ts-ignore

      title: payload?.notification.title,
      // @ts-ignore

      body: payload?.notification.body,
      // @ts-ignore

      data: payload?.data,
    });
  });

  return <Toaster />;
};

export default Notification;

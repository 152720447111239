import { useAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CgMenu, CgSearch } from 'react-icons/cg';

import { pageLoading } from '@/lib/store';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import { TkT } from '@/tayara-kit/icons';
import Text from '@/tayara-kit/Text';

const HandsetNavbarSkeleton = () => {
  const [isLoading] = useAtom(pageLoading);
  const router = useRouter();
  const isNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');

  return (
    <>
      <div
        className={`w-full h-[45px] ${isNeuf ? 'bg-neuf-100' : 'bg-primary '}`}
      >
        <div
          className={`w-full md:flex justify-between items-center px-0 md:px-16 py-2 max-w-[1920px] h-[45px] mx-auto ${
            isNeuf ? 'bg-neuf-100' : 'bg-primary'
          }`}
        >
          <div className="flex w-full items-center gap-x-2">
            <Link href={{ pathname: '/' }} passHref>
              <img
                className="min-w-fit max-w-[120px] transition-all duration-300"
                src={
                  isNeuf ? '/media/immoExpertLogo.webp' : '/media/t-tayara.svg'
                }
                alt="Logo de tayara.tn"
              />
            </Link>
            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white md:hidden 2xl:block"
            />
            <div className="w-[10%] bg-white/50 h-3 my-4 mx-1 rounded-md animate-pulse"></div>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <Text
              className="text-center text-white text-xs italic font-semibold xl:block"
              text={`Contactez-nous : ${
                isNeuf ? '+216 93 941 034' : '+216 95 256 096'
              }  `}
            />
          </div>
          <div className="mr-2 flex w-full gap-x-3 justify-end items-center overflow-hidden">
            <div className="flex items-center justify-center">
              <Text
                className="text-center text-white text-base mr-2 font-semibold italic xl:block"
                text="Explorer"
              />
              <Divider
                orientation={Orientation.VERTICAL}
                className="!h-[45px] rotate-12 text-white"
              />
            </div>

            <div className="w-[10%] bg-white/50 h-3 my-4 mx-1 rounded-md animate-pulse"></div>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <div className="w-[10%] bg-white/50 h-3 my-4 mx-1 rounded-md animate-pulse"></div>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />

            <div className="w-[10%] bg-white/50 h-3 my-4 mx-1 rounded-md animate-pulse"></div>

            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-4 !mt-[3px] !mx-1 text-white"
            />
          </div>
        </div>

        <div className="block md:hidden bg-white h-[100px] w-full mx-auto overflow-hidden">
          <div className="md:hidden flex flex-col justify-center items-center w-full h-full">
            <span className="text-2xl md:text-4xl font-extrabold text-neutral-200">
              Publicité
            </span>
            <img
              className="w-[50px] md:w-[80px] transition-all duration-300"
              src={
                isNeuf ? '/media/immoExpertLogo.webp' : '/media/tayara-logo.svg'
              }
              alt="Logo de tayara.tn"
            />
          </div>
        </div>
        <div className="w-full max-w-[1920px] h-[80px] md:h-[100px] mx-auto flex justify-between items-center border-b-[1px] border-neutral-200 py-4 md:px-16 bg-white">
          <div className="lg:w-full flex items-center justify-start">
            <Link
              href={{ pathname: '/' }}
              passHref
              className="flex justify-between items-center"
            >
              <img
                className="min-w-[70px] max-w-[120px] transition-all duration-300 hidden md:block"
                src={
                  isNeuf ? '/media/immoxxLogo.png' : '/media/tayara-logo.svg'
                }
                alt="Logo de tayara.tn"
              />
              <TkT className="py-2 text-primary md:hidden" size={42} />
            </Link>
          </div>
          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white md:hidden"
          />

          <div className="w-full h-fit flex justify-start items-center mx-2 md:mx-8 gap-x-4 py-0 md:py-2 px-4 md:px-10 shadow-sm shadow-neutral-400 border-neutral-300 outline-offset-0 rounded-3xl border-[1px]">
            <span className="rounded-full p-2 bg-primary">
              <CgSearch strokeWidth={2} className="text-white" size={13} />
            </span>

            <div className="w-[80%] bg-neutral-200 h-2 lg:h-3 my-4 mx-2 rounded-md animate-pulse"></div>
            <div
              className={`pointer-events-none absolute w-full left-0 bottom-[-37.5px] border-t-primary transition-transform duration-300 ${
                isLoading ? 'scale-y-100' : 'scale-y-0 translate-y-[-16px]'
              }`}
            >
              <img
                className="mx-auto mt-[-1px] flex-none"
                src="/media/loader.svg"
                alt="Logo de tayara.tn"
              />
            </div>
          </div>

          <CgMenu
            strokeWidth={1}
            size={38}
            className="mr-4 text-neutral-300 animate-pulse md:hidden"
          />

          <div className="w-full md:flex items-center justify-end hidden">
            <div className="w-[30%] bg-neutral-200 h-3 my-4 mx-2 rounded-md animate-pulse"></div>
            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-5 !mt-[3px] !mx-1 text-neutral-100"
            />

            <div className="w-[30%] bg-neutral-200 h-3 my-4 mx-2 rounded-md animate-pulse"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HandsetNavbarSkeleton;

/* eslint-disable no-nested-ternary */

import React, { RefAttributes } from 'react';

import { TkSpinner } from './icons';

export enum ButtonIconPosition {
  Top,
  Start,
  Bottom,
  End,
}

interface ButtonProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    RefAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
  iconPosition?: ButtonIconPosition;
  light?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      loading,

      /**
       * Button with an icon
       */
      icon,
      /**
       * Icon position, can be Top, Bottom, Start or End
       */
      iconPosition,
      light,
      ...props
    }: ButtonProps,
    ref
  ) => (
    <button
      ref={ref}
      {...props}
      className={`btn normal-case font-bold border-none rounded-lg
    ${
      light &&
      'bg-blue-100 text-blue-500 font-medium border-none hover:bg-blue-100 w-max'
    }
    ${props.className}`}
    >
      <span
        className={`flex  h-full w-full items-center justify-center gap-x-0 md:gap-x-2 gap-y-[2.5px] ${
          iconPosition === ButtonIconPosition.Top
            ? 'flex-col'
            : iconPosition === ButtonIconPosition.Bottom
            ? 'flex-col-reverse'
            : iconPosition === ButtonIconPosition.End
            ? 'flex-row-reverse'
            : 'flex-row'
        }`}
      >
        <span className="ml-0 mr-0">{icon || null}</span>
        {loading ? (
          <TkSpinner />
        ) : (
          <span className="flex align-middle">{children}</span>
        )}
      </span>
    </button>
  )
);
Button.displayName = 'Button';

export default Button;

import { IconType } from 'react-icons';

export enum UserType {
  USER,
  PRO,
}

export interface ITabs {
  [key: string]: JSX.Element;
}

// Dashboard Types
export enum ChangeType {
  INCREASE, // Increase
  DECREASE, // Decrease
}
export interface IStat {
  id: number;
  name: string;
  stat: string;
  icon: IconType;
  change: string;
  changeType: ChangeType;
  previousStat: string;
}

import Link from 'next/link';
import { useRouter } from 'next/router';
import { BiStore } from 'react-icons/bi';
import { FaPercentage } from 'react-icons/fa';
import { HiSparkles } from 'react-icons/hi';
import { IoPricetag } from 'react-icons/io5';
import { useWindowSize } from 'usehooks-ts';

import Divider, { Orientation } from '@/tayara-kit/Divider';
import Text from '@/tayara-kit/Text';

import { NavbarMenu } from './NavbarMenu';
import { useElasticSearchKeyCategories } from '../hooks/useElasticSearchServer';

const NavbarUpper = () => {
  const [, handleNeufClick] = useElasticSearchKeyCategories();
  const windowSize = useWindowSize();
  const router = useRouter();
  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');

  return (
    <div className={`w-full ${isImmoNeuf ? 'bg-neuf-100' : 'bg-primary'}`}>
      <div className="max-w-[1920px] flex justify-between h-[45px] px-16 mx-auto items-center">
        <div className="flex w-fit items-center gap-x-2">
          <Link href="/" passHref>
            <img
              className="min-w-fit max-w-[120px] transition-all duration-300"
              src={
                isImmoNeuf
                  ? '/media/immoExpertLogo.webp'
                  : '/media/t-tayara.svg'
              }
              alt="Logo de tayara.tn"
            />
          </Link>
          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white md:hidden 2xl:block"
          />

          <NavbarMenu />
          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white"
          />
          <Text
            className="text-center text-white text-xs italic font-semibold xl:block"
            text={`Contactez-nous : ${
              isImmoNeuf ? '+216 93 941 034' : '+216 95 256 096'
            }  `}
          />
        </div>
        <div className="mr-2 flex w-fit gap-x-3 items-center">
          <div className="flex items-center justify-center">
            <Text
              className="text-center text-white text-base mr-2 font-semibold italic xl:block"
              text="Explorer"
            />
            <Divider
              orientation={Orientation.VERTICAL}
              className="!h-[45px] rotate-12 text-white"
            />
          </div>
          <Link
            passHref
            href="/shops"
            aria-label="Les boutiques sur tayara.tn"
            className="w-fit flex items-center gap-x-2"
          >
            <BiStore size={19} className="text-white" />
            <Text
              className={`text-white text-xs italic font-semibold hidden md:hidden xl:block ${
                windowSize.width < 1380 ? '!hidden' : ''
              }`}
              text="Nos boutiques"
            />
          </Link>

          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white"
          />

          <Link
            passHref
            href={{ pathname: '/search', query: { productType: 'Neuf' } }}
            onClick={handleNeufClick}
            aria-label="Les produits neuf sur tayara.tn"
            className="w-fit flex items-center gap-x-2"
          >
            <HiSparkles size={19} className="text-white" />
            <Text
              className={`text-white text-xs italic font-semibold hidden md:hidden xl:block ${
                windowSize.width < 1380 ? '!hidden' : ''
              }`}
              text="Produits neufs"
            />
          </Link>

          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white"
          />

          <Link
            passHref
            href="/immoNeuf"
            onClick={handleNeufClick}
            aria-label="Immoexpert by Tayara"
            className="w-fit flex items-center gap-x-2"
          >
            <img
              className="min-w-fit max-w-[120px] transition-all duration-300 xl:block"
              src="/media/immoExpertLogo.webp"
              alt="Logo de Immo-Expert by Tayara"
            />
            <Text
              className={`text-white text-xs italic font-semibold hidden md:hidden xl:block ${
                windowSize.width < 1380 ? '!hidden' : ''
              }`}
              text="Nos projets immoNeuf"
            />
          </Link>

          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white"
          />

          <Link
            passHref
            href="/services"
            aria-label="Les offres et services de tayara.tn"
            className="w-fit flex items-center gap-x-2"
          >
            <IoPricetag size={19} className="text-white" />
            <Text
              className={`text-white text-xs italic font-semibold hidden md:hidden xl:block ${
                windowSize.width < 1380 ? '!hidden' : ''
              }`}
              text="Offres et services"
            />
          </Link>

          <Divider
            orientation={Orientation.VERTICAL}
            className="!h-4 !mt-[3px] !mx-1 text-white"
          />

          <Link
            passHref
            href="/bons-plans-tayara"
            aria-label="Les Bons Plans de tayara.tn"
            className="w-fit flex items-center gap-x-2 neons"
          >
            <FaPercentage size={19} className="text-white" />
            <div
              className={`text-white text-sm italic font-semibold hidden md:hidden xl:block ${
                windowSize.width < 1380 ? '!hidden' : ''
              }`}
              data-text="Bons Plans"
            >
              Bons Plans
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavbarUpper;

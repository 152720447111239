import { IconBaseProps } from 'react-icons';

interface CardMasonryProps extends IconBaseProps {
  className?: string;
  size?: string | number;
}

const CardMasonry: React.FC<CardMasonryProps> = ({ size, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="TiTokenHistory"
    data-name="cards-masonry"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>card</title>
    <rect width="100%" height="100%" rx="12%" fill="currentColor" />
  </svg>
);

export default CardMasonry;

import { IconBaseProps } from 'react-icons';

interface TokenHistoryProps extends IconBaseProps {
  className?: string;
  size?: string | number;
}
const TForTayara: React.FC<TokenHistoryProps> = ({ size, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="TiTokenHistory"
    data-name="t-for-tayara"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.28,17.49c0,.27,0,.89-.2,1.05l-.53.35a7.28,7.28,0,0,1-4,1.11q-4.5,0-4.5-3.44c0-.7,0-1.72.08-3.1s.07-2.42.07-3.1c0-.45-.28-.69-.8-.69H6.26c-.09,0-2.31.28-2.38-.13a9.37,9.37,0,0,1,.07-4c.15-.3,1.82-.35,2.5-.39a.84.84,0,0,0,.67-.62,7.38,7.38,0,0,0,0-1.33c0-1-.07-1.33-.07-1.4C7.05.6,7.16,0,7.39,0S8.07,0,8.6.07L10,.15c.57,0,1.15,0,2.28.09.27,0,.38.11.38.25,0,.42,0,1.06-.11,1.9s-.09,1.49-.09,1.89.15.73.46.77a15,15,0,0,0,1.64.2,6.65,6.65,0,0,1,1.18,0c.16,0,.49,0,.49.57a3.89,3.89,0,0,0,0,.49c0,.47.05.71.05,1s-.05.74-.05,1.22a2.41,2.41,0,0,1-.08.89.38.38,0,0,1-.43.33c-.22,0-.33,0-.82,0l-1,0-1.19.13c-.16,0-.23.2-.23.67v1.11c0,.5,0,.86,0,1.12a4,4,0,0,0,.29,1.8c.29.44.6.58,1.42.58a2.68,2.68,0,0,0,.74-.09,9.5,9.5,0,0,1,1-.18c.22,0,.33.11.33.34a7.85,7.85,0,0,0,0,1C16.32,16.72,16.28,17.18,16.28,17.49Z"
    />
  </svg>
);

export default TForTayara;

import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { APP_CONFIG } from '@/app-config';

interface IAlternateLang {
  hrefLang: string;
  href: string;
}

type IMetaProps = {
  title: string;
  description: string;
  canonical?: string;
  altLangs?: IAlternateLang[];
};

const Meta = ({ title, description, altLangs, canonical }: IMetaProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <meta name="theme-color" content="#E84A3B" />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={canonical}
        languageAlternates={altLangs}
        openGraph={{
          title,
          description,
          url: canonical,
          locale: APP_CONFIG.locale,
          site_name: APP_CONFIG.site_name,
        }}
      />
    </>
  );
};

export { Meta };

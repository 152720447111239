import { FC, useRef, useState } from 'react';

import { useEffectOnce } from 'usehooks-ts';

import {
  FilterRequestObject,
  SearchRequestObject,
} from '@/api/elasticsearch/models';
import { searchElasticApi } from '@/api/fetch/rest-search-api';
import { APP_CONFIG } from '@/app-config';
import { CarouselEvents } from '@/modules/analytics/event-types';
import useRudderTrack from '@/modules/analytics/hooks/useRudderTrack';
import { CarouselType } from '@/modules/analytics/types';
import XCarousel from '@/tayara-kit/XCarousel';

import CompactClassifiedCard from './CompactClassifiedCard';
import { useExtendedSearch } from '../../hooks/useExtendedSearch';
import { IListingItem } from '../../types';

interface RecentClassifiedsProps {
  topOffset: number;
}

const RecentClassifiedsList: FC<RecentClassifiedsProps> = ({ topOffset }) => {
  const [visitedClassifieds, setVisitedClassifieds] = useState<IListingItem[]>(
    []
  );
  const {
    history,
    refineLocalClassifiedsHistory,
    resetLocalClassifiedsHistory,
  } = useExtendedSearch();
  const parentRef = useRef<HTMLDivElement>(null);
  const { trackCarouselClassifiedClick } = useRudderTrack();

  useEffectOnce(() => {
    if (history && history.length > 0) {
      const filterRequest: FilterRequestObject = {
        categoryId: '',
        subCategoryId: '',
        adParamsMap: {},
        rangeAdParamsMap: {},
        governorate: '',
        delegation: [],
        minPrice: 0,
        maxPrice: 0,
        productTypeList: [],
        level: 0,
        state: 2,
        userid: '',
        ids: history,
      };
      const searchRequest: SearchRequestObject = {
        query: '',
        offset: 0,
        limit: APP_CONFIG.VISITED_HISTORY_MAX_LENGTH,
        sort: 0,
        filter: filterRequest,
      };

      searchElasticApi(searchRequest, false)
        .then(([[data, _]]) => {
          // if response is empty reset local storage to empty array
          if (data.length === 0) {
            setVisitedClassifieds([]);
            resetLocalClassifiedsHistory();
            return;
          }
          // Relocate response classifieds to match the sorting on the local storage
          const tmp = history.flatMap(
            (id) => data.find((item) => item.id === id) || []
          );
          // If some classifieds are removed, recalibrate the local history with mailisearch resposnse

          setVisitedClassifieds(tmp);
          if (data.length < history.length) {
            const aux = data.flatMap(
              (item) => history.find((id) => id === item.id) || []
            );
            refineLocalClassifiedsHistory(aux);
          }
        })
        .catch((err) => new Error(err));
    }
  });

  return (
    <div>
      <p className="text-sm font-semibold text-neutral-400 mt-1">
        Vos annonces récemment visitées
      </p>
      <div ref={parentRef} className="relative mt-2" data-parent="true">
        <XCarousel
          portalEl={parentRef.current}
          /* onStopScroll={(progress) => {
            trackCarouselScroll(CarouselEvents.SCROLL_CAROUSEL, {
              scroll_percentage: progress,
              carousel_type: CarouselType.USER_VISITED_HISTORY,
              relative_data: null,
            });
          }} */
          topOffset={topOffset}
        >
          <ul className="h-full flex gap-px pt-1">
            {visitedClassifieds.length > 0 ? (
              visitedClassifieds.map((data, index) => (
                <li key={index}>
                  <CompactClassifiedCard
                    onClick={() =>
                      trackCarouselClassifiedClick(
                        CarouselEvents.CLICK_CLASSIFIED,
                        {
                          carousel_type: CarouselType.USER_VISITED_HISTORY,
                          classified_details: data,
                          classified_position: index,
                        }
                      )
                    }
                    classifiedData={data}
                  />
                </li>
              ))
            ) : (
              <p className="m-auto font-extrabold my-11 text-2xl text-neutral-200">
                Les annonces que vous avez récemment visitées seront affichées
                içi
              </p>
            )}
          </ul>
        </XCarousel>
      </div>
    </div>
  );
};

export default RecentClassifiedsList;

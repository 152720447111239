import React, { useEffect, useMemo, useState } from 'react';

// @ts-ignore
import { useAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HiOutlineUserCircle, HiPlusCircle } from 'react-icons/hi';
import { useEffectOnce, useReadLocalStorage } from 'usehooks-ts';

import { rudderstackTrackEvent } from '@/api/rudderstack/client';
import Notification from '@/firebase/Notification';
import useLoginHook from '@/modules/auth/hooks/useLoginHook';
import Divider, { Orientation } from '@/tayara-kit/Divider';
import { TkT } from '@/tayara-kit/icons';
import ProfileAvatar from '@/tayara-kit/ProfileAvatar';
import { NEXT_API_URL } from 'environment';

import { NavbarSearchInput } from './LargeSearchInput/NavbarSearchInput';
import NavbarUpper from './NavbarUpper';
import { AuthTokenAtom } from '../store/grpc-free-store';

const Navbar = () => {
  // @ts-ignore
  const [authToken] = useAtom(AuthTokenAtom);
  const headless = useReadLocalStorage('headless');
  // const authToken = useReadLocalStorage<string>('auth_token');
  // @ts-ignore
  const current = useReadLocalStorage('userid')?.toString();
  // const [, setConnected] = useState(false);
  // const centrifuge = new Centrifuge(environment.livechat);
  const expiredIn = useReadLocalStorage<number>('expires_in');
  const [, setCountUnreadMsg] = useState(0);
  const router = useRouter();
  const headlessParam = router.query.headless;

  useEffectOnce(() => {
    if (current) {
      fetch(`${NEXT_API_URL}/marketplace/get_unread_discussions_count/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: authToken,
          userRequest: {
            kcid: current,
          },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setCountUnreadMsg(res?.count);
        });
    }
  });
  // useEffectOnce(() => {
  //   if (typeof authToken === 'string') {
  //     centrifuge.setToken(authToken);
  //   }

  //   centrifuge.on('connect', () => {
  //     setConnected(true);
  //   });
  //   centrifuge.on('disconnect', () => {
  //     setConnected(false);
  //   });
  //   centrifuge.connect();
  //   centrifuge.subscribe(`messages#${current}`, (message) => {
  //     if (message) {
  //       setCountUnreadMsg(get(message, 'data.unread_discussions_count', 0));
  //     }
  //   });
  //   return () => {
  //     centrifuge.disconnect();
  //   };
  // });

  const isAuthenticated = useMemo(
    () => Date.now() / 1000 < +(expiredIn || 0),
    [expiredIn]
  );
  const { logout } = useLoginHook();

  const [scrollDirection, setScrollDirection] = useState('none');
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  // const [navbarUpperVisible, setNavbarUpperVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;

    // Determine scrolling direction
    if (currentScrollPosition > lastScrollPosition) {
      setScrollDirection('down');
    } else if (currentScrollPosition < lastScrollPosition) {
      setScrollDirection('up');
    }

    // Update last scroll position
    setLastScrollPosition(currentScrollPosition);

    // Show/hide NavbarUpper based on scrolling direction
    if (scrollDirection === 'up') {
      // setNavbarUpperVisible(true); // Scrolling up, show the NavbarUpper
    }
  };

  useEffect(() => {
    if (headlessParam === 'true') {
      if (window !== undefined) {
        localStorage.setItem('headless', 'true');
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection, lastScrollPosition]);
  const isImmoNeuf =
    router.pathname
      .split('/')
      .some((segment) => segment.toLowerCase() === 'immoneuf') ||
    router?.query?.category === 'ImmoNeuf' ||
    router?.query.slug?.includes('Immobilier Neuf');
  return (
    <>
      <Notification />
      {!headless ? (
        <div className="w-full flex flex-col fixed z-[9999] h-fit top-0  ">
          <NavbarUpper />
          <div
            className={`w-full mx-auto flex justify-center items-center border-b-[1px] border-neutral-200 h-[100px] py-4 px-auto bg-white`}
          >
            <div className="w-full flex justify-between items-center max-w-[1920px] px-16">
              {/* Left navigation items */}
              <div className="w-fit flex justify-start items-center  h-[4rem]">
                {/* Brand / logo */}

                <Link
                  href={{ pathname: '/' }}
                  passHref
                  className="flex justify-between items-center"
                >
                  <img
                    className={`min-w-[70px] max-w-[120px] transition-all duration-300 hidden xl:block`}
                    src={
                      isImmoNeuf
                        ? '/media/immoxxLogo.png'
                        : '/media/tayara-logo.svg'
                    }
                    alt="Logo de tayara.tn"
                  />
                </Link>

                {/* <div
                className={`flex items-center ${
                  windowSize.width < 1275 ? '!hidden' : ''
                } `}
              >
                <Divider
                  orientation={Orientation.VERTICAL}
                  className={'!h-5 !ml-1 hidden md:hidden xl:block'}
                />
                <Text
                  className="italic text-center text-neutral-400 text-opacity-75 text-sm font-normal hidden md:hidden xl:block"
                  text="Acheter et vendre gratuitement près de chez vous"
                />
              </div> */}
                <Link href={{ pathname: '/' }} passHref>
                  <TkT
                    className="py-2 text-primary hidden md:block xl:hidden"
                    size={48}
                  />
                </Link>
                {/* <NavbarMenu /> */}
              </div>
              {
                // router.pathname !== '/' &&
                // !router.pathname.startsWith('/ads') &&
                // !router.pathname.startsWith('/search') &&
                // <SearchInput navbar />

                <NavbarSearchInput />
              }
              {/* Right navigation items */}
              <div className="w-fit flex justify-end items-center ">
                {/* This will be reactivated in a later version */}
                {/* <button className="text-neutral-500 focus:rotate-45 transition-transform">
      <HiCog size={22} />
    </button> */}

                {/* <RatingMenu position={RatingMenuPosition.Left} /> */}
                {/* <Divider orientation={Orientation.VERTICAL} /> */}

                <span
                  className={`flex rounded-xl ${
                    isImmoNeuf
                      ? 'bg-neuf-100 hover:bg-neuf-200'
                      : 'bg-primary hover:bg-primary-focus'
                  } `}
                >
                  <Link
                    passHref
                    href={'/post-listing'}
                    aria-label="Publier annonce"
                    onClick={() =>
                      rudderstackTrackEvent(
                        'Clicked on listing insertion button'
                      )
                    }
                    className="flex items-center p-2 text-white"
                  >
                    <HiPlusCircle size={22} className={'mx-3'} />

                    <h3 className="pr-3 pb-[1px] text-white text-sm font-semibold hidden md:hidden xl:block w-max">
                      Publier une annonce
                    </h3>
                  </Link>
                </span>

                <Divider
                  orientation={Orientation.VERTICAL}
                  className={'!h-5'}
                />
                {isAuthenticated ? (
                  <ProfileAvatar logout={logout} />
                ) : (
                  <Link href={{ pathname: '/login' }} replace passHref>
                    <button
                      aria-label="Se connecter"
                      className="flex items-center p-2 rounded-xl hover:bg-neutral-200 border border-neutral-300 border-opacity-75 text-neutral-500 text-sm  font-bold font-arabic hover:!border-solid "
                    >
                      <HiOutlineUserCircle size={22} className={'mx-3'} />
                      <h3 className="text-neutral-500  text-sm pr-3 font-semibold block md:hidden xl:block w-max">
                        Se connecter
                      </h3>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/* {router.pathname === '/' && navbarUpperVisible && <NavbarLower />} */}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Navbar;

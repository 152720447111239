// package: identity
// file: identity.proto

var identity_pb = require("./identity_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Identity = (function () {
  function Identity() {}
  Identity.serviceName = "identity.Identity";
  return Identity;
}());

Identity.GetUser = {
  methodName: "GetUser",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.GetUserRequest,
  responseType: identity_pb.GetUserResponse
};

Identity.UpdateLastLogin = {
  methodName: "UpdateLastLogin",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.UpdateLastLoginRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.GenerateOTP = {
  methodName: "GenerateOTP",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.GenerateOTPRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.VerifyOTP = {
  methodName: "VerifyOTP",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.VerifyOTPRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.VerifyEmailOTP = {
  methodName: "VerifyEmailOTP",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.VerifyEmailOTPRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.GenerateEmailOTP = {
  methodName: "GenerateEmailOTP",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.GenerateEmailOTPRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.Signup = {
  methodName: "Signup",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.UserRequest,
  responseType: identity_pb.UserResponse
};

Identity.MigrateUser = {
  methodName: "MigrateUser",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.UserRequest,
  responseType: identity_pb.UserResponse
};

Identity.CheckMigratedUser = {
  methodName: "CheckMigratedUser",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.CheckMigratedUserRequest,
  responseType: identity_pb.UserResponse
};

Identity.ChangeUserEmail = {
  methodName: "ChangeUserEmail",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.ChangeUserEmailRequest,
  responseType: identity_pb.UserResponse
};

Identity.ValidateEmail = {
  methodName: "ValidateEmail",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.VerifyEmailOTPRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Identity.ChangeUserPassword = {
  methodName: "ChangeUserPassword",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.ChangeUserPasswordRequest,
  responseType: identity_pb.ChangeUserPasswordResponse
};

Identity.CheckUserWithUsername = {
  methodName: "CheckUserWithUsername",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.CheckUserWithUsernameRequest,
  responseType: identity_pb.CheckUserWithUsernameResponse
};

Identity.CheckUserWithEmail = {
  methodName: "CheckUserWithEmail",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.CheckUserWithEmailRequest,
  responseType: identity_pb.CheckUserWithEmailResponse
};

Identity.UpdateUserData = {
  methodName: "UpdateUserData",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.UpdateUserDataRequest,
  responseType: identity_pb.UpdateUserDataResponse
};

Identity.ChangeUserPasswordWithVerifyOTP = {
  methodName: "ChangeUserPasswordWithVerifyOTP",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.ChangeUserPasswordWithVerifyOTPRequest,
  responseType: identity_pb.ChangeUserPasswordResponse
};

Identity.GetUserByKcId = {
  methodName: "GetUserByKcId",
  service: Identity,
  requestStream: false,
  responseStream: false,
  requestType: identity_pb.GetUserByKcidRequest,
  responseType: identity_pb.CheckUserWithEmailResponse
};

exports.Identity = Identity;

function IdentityClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

IdentityClient.prototype.getUser = function getUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.GetUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.updateLastLogin = function updateLastLogin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.UpdateLastLogin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.generateOTP = function generateOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.GenerateOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.verifyOTP = function verifyOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.VerifyOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.verifyEmailOTP = function verifyEmailOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.VerifyEmailOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.generateEmailOTP = function generateEmailOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.GenerateEmailOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.signup = function signup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.Signup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.migrateUser = function migrateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.MigrateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.checkMigratedUser = function checkMigratedUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.CheckMigratedUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.changeUserEmail = function changeUserEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.ChangeUserEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.validateEmail = function validateEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.ValidateEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.changeUserPassword = function changeUserPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.ChangeUserPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.checkUserWithUsername = function checkUserWithUsername(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.CheckUserWithUsername, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.checkUserWithEmail = function checkUserWithEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.CheckUserWithEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.updateUserData = function updateUserData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.UpdateUserData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.changeUserPasswordWithVerifyOTP = function changeUserPasswordWithVerifyOTP(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.ChangeUserPasswordWithVerifyOTP, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IdentityClient.prototype.getUserByKcId = function getUserByKcId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Identity.GetUserByKcId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.IdentityClient = IdentityClient;


import { IconBaseProps } from 'react-icons';

interface DualSpinnersProps extends IconBaseProps {
  className?: string;
  size?: string | number;
}
const DualSpinners: React.FC<DualSpinnersProps> = ({ size = 20, ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0}
    width={size || '1em'}
    height={size || '1em'}
    id="tayara-token"
    data-name="tayara token"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      margin: 'auto',
      background: 'rgb(255, 255, 255)',
      display: 'block',
      shapeRendering: 'auto',
    }}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <title>loader</title>
    <circle
      cx="50"
      cy="50"
      r="32"
      strokeWidth="8"
      stroke="#fe718d"
      strokeDasharray="50.26548245743669 50.26548245743669"
      fill="none"
      strokeLinecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
  </svg>
);
export default DualSpinners;

<svg></svg>;

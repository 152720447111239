import { listAdsCurrentVersion } from '@/api/fetch/marketplace-api';
import { Version } from '@/api/gen-protos/ads_pb';
import {
  FilterAndDatesSearch,
  TextFilter,
  RangeFilter,
  PaginationParams,
  PriceRange,
  LocationRequest,
} from '@/api/gen-protos/discovery_pb';
import {
  TYPE_SELECT,
  TYPE_RANGE,
  LAST_NEWS,
  LIMIT,
  ALL_GOVERNORATE,
  ALL_DELEGATION,
} from '@/lib/constants';
import {
  categories,
  governorates,
  delegations,
  CategoryHelper,
} from '@/lib/helpers';
import { environment } from 'environment';

import {
  ICategoryParamList,
  ICategoryParam,
  QueryType,
  IListingItem,
} from '../types';

/**
 * formats the passed locale string to the proper format, e.g. ar-tn becomes ar-TN
 * @param str string
 * @returns string
 */
export const formatLocale = (str: string): string => {
  const couple = str.split('-');
  if (couple.length > 1) {
    return `${couple[0]?.toLowerCase()}-${couple[1]?.toUpperCase()}`;
  }
  return str.toLowerCase();
};

const addSpaces = (str: string) =>
  Array.from(str)
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    ?.toString()
    .split('')
    .reverse()
    .join('');

/**
 * Makes large price values more readable
 * @param price item price
 */
export const formatPrice = (price: number) => {
  const strPrice = price.toString().split('.');
  let result;
  if (strPrice[0]) {
    result = addSpaces(strPrice[0]);
  }
  if (strPrice[1]) {
    result = result?.concat('.', strPrice[1]);
  }
  return result?.toString();
};

/**
 *  get sort type and return RPC
 * @param s string
 * @returns  FilterAndDatesSearch.SortMechanism
 */
export const selectSort = (s: string) => {
  let sortMechanism;
  switch (s) {
    case 'pertinence':
      sortMechanism = FilterAndDatesSearch.SortMechanism.RELEVANCE;
      break;
    case 'timestamp':
      sortMechanism = FilterAndDatesSearch.SortMechanism.TIMESTAMP;

      break;
    case 'prix_bas':
      sortMechanism = FilterAndDatesSearch.SortMechanism.ASCPRICE;

      break;
    case 'prix_eleve':
      sortMechanism = FilterAndDatesSearch.SortMechanism.DESCPRICE;

      break;

    default:
      sortMechanism = FilterAndDatesSearch.SortMechanism.TIMESTAMP;

      break;
  }
  return sortMechanism;
};

/**
 *  find selectedCategory/subcategory Object by name
 * @param categoryName string
 * @returns  CategoryHelper | undefined
 */

export const findCategory = (
  categoryName: string
): CategoryHelper | undefined =>
  categories.find((c) => c.name === categoryName);

/**
 * find governorate Object by name
 * @param govornorateName string
 * @returns  Governorate
 */

const findGovornorate = (govornorateName: string) =>
  governorates.find((g) => g.name === govornorateName);

/**
 * find delegation Object by name
 * @param delegationName string
 * @returns  Governorate
 */
const findDelegation = (delegationName: string) =>
  delegations.find((g) => g.name === delegationName);

/**
 * find ICategoryParamList from  dynamic query values
 * @param queryParamKey string
 * @param subCategoryParams ICategoryParamList
 * @returns   ICategoryParam | undefined
 */
const findICategoryParamFromQueryName = (
  queryParamKey: string,
  subCategoryParams: ICategoryParamList
): ICategoryParam | undefined =>
  subCategoryParams.find(
    (subc) =>
      subc.name.toLocaleLowerCase() === queryParamKey.toLocaleLowerCase()
  );

/**
 * transfert all dynamic query value to grpc request
 * @param query string
 * @param subCategoryParams ICategoryParamList
 * @param filterAndDatesSearch FilterAndDatesSearch
 * @returns  void
 */
const filterParamsToRpcObject = (
  query: any,
  subCategoryParams: ICategoryParamList,
  filterAndDatesSearch: FilterAndDatesSearch
) => {
  Object.keys(query).forEach((queryParamKey) => {
    if (query[queryParamKey]) {
      const categoryParam: ICategoryParam | undefined =
        findICategoryParamFromQueryName(queryParamKey, subCategoryParams);

      if (categoryParam) {
        if (categoryParam.type === TYPE_SELECT) {
          const arrayValue = query[queryParamKey].split(',');
          const textFilter = new TextFilter();
          textFilter.setFiltervalueList(arrayValue);
          filterAndDatesSearch
            .getTextfilterMap()
            .set(queryParamKey, textFilter);
        } else if (categoryParam.type === TYPE_RANGE) {
          const min = parseInt(query[queryParamKey].split(',')[0], 10);
          const max = parseInt(query[queryParamKey].split(',')[1], 10);
          const rangeFilter = new RangeFilter();
          rangeFilter.setMin(min);
          rangeFilter.setMax(max);
          filterAndDatesSearch
            .getRangefilterMap()
            .set(queryParamKey, rangeFilter);
        }
      }
    }
  });
};

/**
 *  function to transfert query variables to grpc request
 * @param query string
 * @param subCategoryParams ICategoryParamList
 
 * @returns  FilterAndDatesSearch
 */
export const queryToGrpcRequest = (
  query: QueryType,
  subCategoryParams?: ICategoryParamList
): FilterAndDatesSearch | null => {
  const {
    sort,
    category,
    subCategory,
    governorate,
    delegation,
    price,

    pageIndex,
    previousPageData,
    ...rest
  } = query;
  if (previousPageData && previousPageData.length < LIMIT) return null;

  const selectedDelegation = findDelegation(delegation);
  const selectedGovernorate = findGovornorate(governorate);
  const selectedCategory = findCategory(category);
  const selectedSubCategory = findCategory(subCategory);

  const filterAndDatesSearch = new FilterAndDatesSearch();
  if (rest && subCategoryParams)
    filterParamsToRpcObject(rest, subCategoryParams, filterAndDatesSearch);

  filterAndDatesSearch.setSortmechanism(selectSort(sort));
  if (selectedCategory?.id && selectedCategory?.id !== LAST_NEWS) {
    if (selectedCategory?.id && !selectedSubCategory?.id) {
      filterAndDatesSearch.setCategoryid(selectedCategory.id);
    } else if (selectedSubCategory?.id) {
      filterAndDatesSearch.setSubcategoryid(selectedSubCategory?.id);
    }
  }
  const paginationParams = new PaginationParams();

  paginationParams.setLimit(LIMIT);
  paginationParams.setSkip(LIMIT * (pageIndex ?? 0));
  filterAndDatesSearch.setPaginationparams(paginationParams);
  if (price) {
    const min = Number(price.split(',')[0]);
    const max = Number(price.split(',')[1]);

    const priceRange = new PriceRange();
    priceRange.setMinprice(min);

    priceRange.setMaxprice(max);
    filterAndDatesSearch.setPrice(priceRange);
  }

  if (selectedGovernorate && selectedGovernorate?.id !== ALL_GOVERNORATE) {
    const location = new LocationRequest();
    location.setGovernorate(selectedGovernorate.name);
    if (selectedDelegation && selectedDelegation?.id !== ALL_DELEGATION) {
      location.setDelegation(selectedDelegation.name);
    }

    filterAndDatesSearch.setLocation(location);
  }
  filterAndDatesSearch
    .getFilterparamsMap()
    .set('currentversion.state', Version.State.ACTIVE.toString());

  return filterAndDatesSearch;
};

/**
 *  function to fetch rpc query from swr function
 * @param query string
 * @param subCategoryParams ICategoryParamList
 
 * @returns  Promise<IListingItem[]>
 */
export const swrFetcher = async (
  keys: any,
  subCategoryParams?: ICategoryParamList
) => {
  const filterDataSearch = queryToGrpcRequest(keys, subCategoryParams);

  if (!filterDataSearch) return [];

  const data = await listAdsCurrentVersion(filterDataSearch, '');
  // const dataCount = await GetTotalAdsCount(filterDataSearch, '');

  return data;
};

export const uploadImages = (files: File[], token: string) => {
  const formData = new FormData();
  files.forEach((element: File) => {
    formData.append('upload[]', element);
  });
  const reqHeader = {
    Authorization: `Bearer ${token}`,
  };
  return fetch(environment.uploadImagesHost, {
    headers: reqHeader,
    method: 'POST',
    body: formData,
  });
};

export const genSearchLink = (params: IMakeSitemapField): string => {
  let p = '/ads/';
  if (params.category) {
    p += `c/${params.category}/`;
    if (params.subCategory) p += `${params.subCategory}/`;
  }
  if (params.governorate) {
    p += `l/${params.governorate}/`;
    if (params.delegation) p += `${params.delegation}/`;
  }
  if (params.productType) p += `t/${params.productType}/`;
  if (params.q) p += `k/${params.q}/`;
  return p;
};

export interface IMakeSitemapField {
  q?: string;
  category?: string;
  subCategory?: string;
  governorate?: string;
  delegation?: string;
  productType?: string;
}

export const genItemLink = (item: IListingItem): string => {
  let p = '/item/';

  p += `${encodeURIComponent(item.id)}/`;

  const encodedCategory = categories
    .find((cat) => item.metadata?.subCategory === cat.id)
    ?.name.trim();
  p += `${encodeURIComponent(encodedCategory || '')}/`;

  const encodedGovernorate = item.location?.governorate
    ?.replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '_')
    .trim();
  p += `${encodeURIComponent(encodedGovernorate || '')}/`;

  const encodedDelegation = item.location?.delegation
    ?.replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '_')
    .trim();
  p += `${encodeURIComponent(encodedDelegation || '')}/`;
  const encodedTitle = encodeURIComponent(
    item.title
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/\s+/g, '_')
      .trim()
  );
  p += `${encodedTitle}/`;

  return p;
};
